// @flow

import React from 'react';
import { Container } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ClipCollection from './ClipCollection.react';
import { collectionCreateNew, listRemoveClipIdx } from '../../actions/clip.react';

export default function CollectionCreator(): React$MixedElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { workspaceId } = useParams();

  const cards = useSelector((state) => state.clip.clips);

  const handleClipRemove = (index: number) => dispatch(listRemoveClipIdx(index));

  const handleCreateNewCollection = () => {
    dispatch(collectionCreateNew(workspaceId)).then((id: ?string) => {
      if (id != null) {
        navigate(`/ws/${workspaceId}/create/${id}`);
      }
    });
  };

  return (
    <Container fluid>
      <ClipCollection
        clipList={cards}
        header="Timeline"
        onSubmit={handleCreateNewCollection}
        onClipRemove={handleClipRemove}
      />
    </Container>
  );
}
