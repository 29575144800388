// @flow

import { sample } from './serialGraphSample';
import { EditGraphClip, EditGraph, EditGraphMedia, EditGraphCut } from './serialGraphStructure';
import {
  createModelSchema,
  primitive,
  reference,
  list,
  object,
  identifier,
  serialize,
  deserialize,
  map,
  alias
} from 'serializr';

function fetchMediaData(mediaPath: string, context: any) {
  return context.parentContext.target.media?.[mediaPath];
}

function findMediaByRef(mediaPath: string, callback: any, context: any) {
  callback(null, fetchMediaData(mediaPath, context));
}

function fetchClipData(clipPath: string, context: any) {
  return context.parentContext.target.clips?.[clipPath];
}

function findClipByRef(clipPath: string, callback: any, context: any) {
  callback(null, fetchClipData(clipPath, context));
}

function findEditGraphCut(index: number, context: any) {
  return context.target.cuts?.[index];
}

function findEditGraphCutByIndex(index: number, callback: any, context: any) {
  callback(null, findEditGraphCut(index, context));
}

// function findChildren(index: number, context: any) {
//   return context.target.edges?.[index];
// }

// Create model schemas

export type TVideoGraphCut = {
  index: string,
  offset: number,
  clipPath: string,
  start: number,
  end: number,
  length: number,
  mediaID: string,
  clipID: string
};

createModelSchema(EditGraphCut, {
  index: identifier(),
  offset: primitive(),
  start: primitive(),
  end: primitive(),
  length: primitive(),
  mediaID: primitive(),
  media: alias('mediaID', reference(EditGraphMedia, findMediaByRef)),
  clipID: primitive(),
  clip: alias('clipID', reference(EditGraphClip, findClipByRef))
});

export type TVideoGraphClip = {
  oid: string,
  clipPath: string,
  clipStartTime: number,
  clipEndTime: number,
  clipLengthTime: number,
  thumbnailURL: string
};

createModelSchema(EditGraphClip, {
  oid: identifier(),
  thumbnailURL: primitive(),
  clipPath: primitive(),
  clipStartTime: primitive(),
  clipEndTime: primitive(),
  clipLengthTime: primitive()
});

export type TVideoGraphMedia = {
  oid: string,
  mediaPath: string,
  mediaURL: string,
  spriteSheetURL: string,
  storageURL: string,
  thumbnailURL: string
};

createModelSchema(EditGraphMedia, {
  oid: identifier(),
  mediaPath: primitive(),
  mediaURL: primitive(),
  spriteSheetURL: primitive(),
  storageURL: primitive(),
  thumbnailURL: primitive()
});

export type TVideoGraphJSON = {|
  wizardID: string,
  wizardPath: string,
  media: { [string]: TVideoGraphMedia },
  clips: { [string]: TVideoGraphClip },
  cuts: { [string]: TVideoGraphCut },
  edges: { [string]: Array<string> },
  buildIndex: number
|};

createModelSchema(EditGraph, {
  oid: primitive(),
  wizardID: primitive(),
  wizardPath: primitive(),
  media: map(object(EditGraphMedia)),
  clips: map(object(EditGraphClip)),
  cuts: map(object(EditGraphCut)),
  edges: map(list(reference(EditGraphCut, findEditGraphCutByIndex))),
  buildIndex: primitive()
});

export function treeEditorSerializer(serializedData: EditGraph): TVideoGraphJSON {
  const json = serialize(serializedData);
  return json;
}

export function treeEditorDeserializer(deserializedData: EditGraph): any {
  const DAGEditGraph = deserialize(EditGraph, deserializedData);
  // DAGEditGraph.reconstructor();
  return DAGEditGraph;
}

export function treeEditorSample(): any {
  const DAGEditGraph: EditGraph = treeEditorDeserializer(sample);
  return DAGEditGraph;
}

export function treeEditorTEST(): any {
  const DAGEditGraph: EditGraph = treeEditorDeserializer(sample);
  // const del = DAGEditGraph.cuts[18];
  // DAGEditGraph.removeEditGraphCut(del);
  // DAGEditGraph.addEditGraphCut(del);
  // DAGEditGraph.addEdge(DAGEditGraph.cuts[14], DAGEditGraph.cuts[11]);
  // DAGEditGraph.addEdge(DAGEditGraph.cuts[13], DAGEditGraph.cuts[14]);
  // DAGEditGraph.depthFirstSearch();
  // console.log(DAGEditGraph);
  return treeEditorSerializer(DAGEditGraph);
}

export function treeEditorCycle(): any {
  const DAGEditGraph = new EditGraph();

  // const cuty1 = new EditGraphCut();
  // const cuty2 = new EditGraphCut();
  // const cuty3 = new EditGraphCut();

  // DAGEditGraph.addCut(cuty1);
  // DAGEditGraph.addCut(cuty2);
  // DAGEditGraph.addCut(cuty3);

  // DAGEditGraph.addEdge(cuty1, cuty2);
  // DAGEditGraph.addEdge(cuty1, cuty3);
  // DAGEditGraph.addEdge(cuty2, cuty3);
  // DAGEditGraph.addEdge(cuty3, cuty1);

  // const del = DAGEditGraph.cuts[18];
  // DAGEditGraph.removeEditGraphCut(del);
  // DAGEditGraph.addEditGraphCut(del);
  // DAGEditGraph.addEdge(DAGEditGraph.cuts[14], DAGEditGraph.cuts[11]);
  // DAGEditGraph.addEdge(DAGEditGraph.cuts[13], DAGEditGraph.cuts[14]);
  // DAGEditGraph.depthFirstSearch();
  // console.log(DAGEditGraph);
  return DAGEditGraph;
}
