// @flow

import type { fragmentContentLinks } from './fragments/fragmentContentLinks';
import type { DocumentReference, DocumentSnapshot } from './utils/firebaseTypes';
import type { gcpTypeDuration } from './utils/googleTypes';
import type { typeCore } from './utils/typeCore';
import { initCore } from './utils/typeCore';

export type TimelineExportInputs = { key: string, uri: string };
export type typeTimelineExportEditList = {
  key: string,
  inputs: Array<string>,
  startTimeOffset: gcpTypeDuration,
  endTimeOffset: gcpTypeDuration
};

export type typeTimelineExportSpec = {|
  inputs: Array<TimelineExportInputs>,
  editList: Array<typeTimelineExportEditList>
|};

export type typeTimeline = {
  ...typeCore,
  ...typeTimelineExportSpec,
  ...fragmentContentLinks,
  title: string,
  videographRef?: string
};

export type typeTimelineFragment = Partial<typeTimeline>;
export type typeTimelineRef = DocumentReference<typeTimeline>;
export type typeTimelineSnap = DocumentSnapshot<typeTimeline>;

export const initTimeline: typeTimelineFragment = {
  ...initCore,
  title: 'runCreateSpriteSheet',
  inputs: [],
  editList: []
};
