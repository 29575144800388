// @flow

export const CHANGE_PAGE = 'CHANGE_PAGE';

const changePage = (currentPage: string) => {
  return {
    type: CHANGE_PAGE,
    currentPage
  };
};

export const updatePage =
  (currentPage: string): ((dispatch: any) => void) =>
  (dispatch: any) => {
    dispatch(changePage(currentPage));
  };
