// @flow

import React, { useEffect, useState } from 'react';
import { firestoreDB } from '../firebase/firebase.react';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import TemplateEditor from '../components/template/TemplateEditor.react';
import LoadingPage from './LoadingPage.react';

export default function TemplateEditPage(): React$MixedElement {
  const [templateData, setTemplateData] = useState();
  const params = useParams();
  useEffect(() => {
    const fetchCollections = async () => {
      const workspaceId = params?.workspace;
      const templateId = params?.template;
      const collectPath = '/ws/' + workspaceId + '/templates/' + templateId;
      const fetchedDoc = await getDoc(doc(firestoreDB, collectPath));
      setTemplateData(fetchedDoc);
    };
    fetchCollections();
  }, [params]);

  if (!templateData || !templateData.exists()) {
    return <LoadingPage />;
  }

  return <TemplateEditor defaultTemplate={templateData?.data()} />;
}
