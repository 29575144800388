// @flow

import type { typeWorkspace } from '../../types/typeWorkspace';
import type { DocumentSnapshot } from '../../types/utils/firebaseTypes';

import WorkspaceListItem from './WorkspaceListItem.react';

import React from 'react';
import { Container, Segment, Item, Header, Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

type Props = $ReadOnly<{
  snap: ?Array<DocumentSnapshot<typeWorkspace>>
}>;

export default function WorkspaceList(props: Props): React$MixedElement {
  return (
    <div className="page-body">
      <Container>
        <Grid columns={2} noPad>
          <Grid.Row verticalAlign="middle">
            <Grid.Column>
              <Header>Select a workspace:</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                icon="plus"
                labelPosition="left"
                content="Create Workspace"
                as={Link}
                to="/start"
                size="small"
                style={{ marginBottom: 0 }}
                positive
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid columns={2}>
          {props.snap?.map((item) => (
            <Grid.Column>
              <Segment>
                <Item.Group key={item?.id}>
                  <WorkspaceListItem snap={item} />
                </Item.Group>
              </Segment>
            </Grid.Column>
          ))}
        </Grid>

        <Header>Shared Workspaces</Header>
      </Container>
    </div>
  );
}
