// @flow

import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import type { dispatchFunc, thunkFunc } from '.';
import { firestoreDB } from '../firebase/firebase.react';
import { initCore } from '../types/utils/typeCore';

import type { fragmentClip } from '../types/fragments/fragmentClip';

export const ADD_CLIP = 'ADD_CLIP';
export const REMOVE_CLIP = 'REMOVE_CLIP';
export const ADD_CLIP_IDX = 'ADD_CLIP_IDX';
export const REMOVE_CLIP_IDX = 'REMOVE_CLIP_IDX';
export const RESET_CLIP = 'RESET_CLIP';

const requestRemoveAllClips = () => {
  return {
    type: RESET_CLIP
  };
};

const requestAddClip = (clip: TClipActions) => {
  return {
    payload: clip,
    type: ADD_CLIP
  };
};

const requestRemoveClip = (clip: TClipActions) => {
  return {
    payload: clip,
    type: REMOVE_CLIP
  };
};

const requestRemoveClipIdx = (idx: number) => {
  return {
    idx,
    type: REMOVE_CLIP_IDX
  };
};

export type TClipActions = fragmentClip;

// export function collectionSave() {
//   // fetchTodoByIdThunk is the "thunk function"
//   return async function fetchTodoByIdThunk(dispatch, getState) {
//     const response = await client.get(`/fakeApi/todo/${todoId}`)
//     dispatch(todosLoaded(response.todos))
//   }

export const collectionCreateNew =
  (workspaceId: string): thunkFunc =>
  async (dispatch: any, getState: any): Promise<?string> => {
    const state = getState();
    const arrayClips = state?.clip?.clips ?? [];

    let collectionId = null;

    if (arrayClips.length > 0) {
      console.log('Thunk: ' + arrayClips.length + ' clips found');

      const docPath = '/ws/' + workspaceId + '/collections';

      const newMediaCollection = collection(firestoreDB, docPath);

      const arrayMedia = new Set<string>();
      const clipRefs = {};
      const mediaRefs: { [key: number]: string } = {};

      arrayClips?.forEach((clip, idx) => {
        const mediaRef = String(clip.mediaRefId);
        mediaRef && arrayMedia.add(mediaRef);
        clipRefs[idx] = clip;
      });

      Array.from(arrayMedia).forEach((ref, idx) => (mediaRefs[idx] = ref));
      console.log(mediaRefs);
      const docRef = await addDoc(newMediaCollection, {
        ...initCore,
        mediaRefs,
        clipRefs
      });

      console.log(docRef);
      collectionId = docRef.id;
    } else {
      console.log('Thunk: No clips found');
    }

    return collectionId;
  };

export const listAddClip =
  (workspaceId: string, mediaId: string, clip: TClipActions): thunkFunc =>
  async (dispatch: any, getState: any): Promise<?string> => {
    const state = getState();

    console.log(clip);

    const visited = [
      {
        mediaRefId: 'mediaRefId',
        clipRefId: 'clipRefId',
        targetObjectId: 'targetObjectId',
        startTimeOffset: 100,
        endTimeOffset: 200
      }
    ];

    state.clip?.clips?.forEach((clip, idx) => {
      visited.push({
        mediaRefId: clip?.mediaRefId ?? clip?.mediaID,
        clipRefId: clip.id,
        targetObjectId: 'targetObjectId',
        startTimeOffset: clip.start,
        endTimeOffset: clip.end
      });
    });

    dispatch(requestAddClip(clip));
    console.log('workspace: ' + workspaceId);

    const inputSearch = [
      {
        mediaRefId: clip?.mediaRefId ?? mediaId,
        clipRefId: clip?.clipID ?? 'clipRefId',
        targetObjectId: 'targetObjectId',
        startTimeOffset: clip.start,
        endTimeOffset: clip.end
      }
    ];

    const results = await dispatch(fetchClipRecommendations(workspaceId, visited, inputSearch));
    //   {
    //     "cnt": 3,
    //     "object_nid": 40,
    //     "entity_id": "/m/02dl1y",
    //     "entity_description": "hat",
    //     "confidence": 0.56185263,
    //     "frame_nid": 29,
    //     "frame_bottom": 0.98454064,
    //     "frame_left": -0.018788273,
    //     "frame_right": 0.98121172,
    //     "frame_top": 0.073429525,
    //     "startTimeOffset": 32.332300000000004,
    //     "endTimeOffset": 40.04,
    //     "taskRefId": "SaGfYhqdmZlFHoKl7VSk",
    //     "mediaRefId": "69MR9zjLM4RnIZdtgxfa",
    //     "workspaceRefId": "xxxx",
    //     "rank": 1,
    //     "estimated_match": 0.9826106916649726
    // }
    for (let i = 0; i < Math.min(results.length, 3); i++) {
      const recommendedClipRaw = results?.[i];
      if (recommendedClipRaw) {
        const workspaceRef = recommendedClipRaw.workspaceRefId;
        const mediaRef = recommendedClipRaw.mediaRefId;

        let mediaData = {};
        // Firebase query using async/await
        try {
          const mediaPath = `/ws/${workspaceRef}/media/${mediaRef}`;
          const docRef = doc(firestoreDB, mediaPath);
          const mediaSnap = await getDoc(docRef);
          mediaData = mediaSnap.data();
          const startTimeOffset = recommendedClipRaw.frame_timeOffset;

          const recommendedClip: TClipActions = {
            ...mediaData?.clipObject,
            id: mediaPath + '/clips/' + recommendedClipRaw.object_nid,
            title: recommendedClipRaw.entity_description,
            source: 'clips',
            length: recommendedClipRaw.endTimeOffset - startTimeOffset,
            start: startTimeOffset,
            end: recommendedClipRaw.endTimeOffset,
            mediaID: recommendedClipRaw.mediaRefId,
            thumbnailURL: mediaData?.thumbnailURL ?? null,
            workspaceRefId: workspaceRef,
            mediaRefId: mediaRef
          };
          console.log(recommendedClipRaw);
          console.log(recommendedClip);
          dispatch(requestAddClip(recommendedClip));
        } catch (error) {
          console.error('Error getting document:', error);
        }
      }
    }
    console.log(results);
  };

// export const listAddClip =
//   (clip: TClipActions): dispatchFunc =>
//   (dispatch: any) => {
//     dispatch(requestAddClip(clip));
//   };

export const listRemoveClip =
  (clip: TClipActions): dispatchFunc =>
  (dispatch: any) => {
    dispatch(requestRemoveClip(clip));
  };

export const listRemoveClipIdx =
  (idx: number): dispatchFunc =>
  (dispatch: any) => {
    dispatch(requestRemoveClipIdx(idx));
  };

type ClipMetadata = {
  mediaRefId: string,
  clipRefId?: string,

  startTimeOffset: number,
  endTimeOffset: number,

  targetObjectId?: string
};

type InputObject = {
  entity_description: string,
  frame_bottom: number,
  frame_left: number,
  frame_right: number,
  frame_top: number,
  confidence: number
};

export const fetchClipRecommendations = (
  workspaceId: string,
  visited: ClipMetadata[],
  inputSearch: ClipMetadata[]
): thunkFunc => {
  return async (dispatch: any, getState: any) => {
    try {
      console.log('workspaceId:', workspaceId);
      console.log('visited:', visited);
      console.log('inputSearch:', inputSearch);
      const response = await fetch(
        'https://us-central1-memory-flow-ai.cloudfunctions.net/asyncClipRecommendation',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ workspaceId, visited, inputSearch })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch recommendations');
      }

      return response.json();
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      throw error;
    }
  };
};
