// @flow

import { searchLabelEntityOptions } from './WizardUtils';
import { useSearchParams } from 'react-router-dom';

import React from 'react';
import { Form } from 'semantic-ui-react';

type Props = {
  name: string,
  placeholder: string,
  defaultValue?: number,
  onChange: (name: string, value: Array<?string>) => void
};

export default function WizardSearchBar(props: Props): React$MixedElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultValue = searchParams.getAll(props.name);

  const handleChange = (e: any, { name, value }: any) => {
    props.onChange(name, value);
    setSearchParams({ [name]: value });
  };

  return (
    <Form.Dropdown
      fluid
      multiple
      search
      selection
      inline
      placeholder={props.placeholder}
      className="reset-max-width"
      name={props.name}
      icon="search"
      options={searchLabelEntityOptions}
      onChange={handleChange}
      defaultValue={defaultValue}
    />
  );
}
