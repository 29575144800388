import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './configureStore.react';
import FirebaseUserProvider from './providers/FirebaseUserProvider.react';
import Router from './VizorAppRouter.react';

const store = configureStore();

function Root() {
  return (
    <Provider store={store}>
      <FirebaseUserProvider>
        <Router />
      </FirebaseUserProvider>
    </Provider>
  );
}

export default Root;
