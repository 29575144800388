// @flow
import { Item, Label, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import type { typeMedia } from '../../types/typeMedia';
import React from 'react';
import { MediaContentType } from '../../types/typeMedia';
type Props = $ReadOnly<{|
  id: string,
  media: typeMedia
|}>;

export default function MediaListItem(props: Props): React$MixedElement {
  const fileIcon = (type: MediaContentType) => {
    switch (type) {
      case MediaContentType.Video:
        return 'file video';
      case MediaContentType.Audio:
        return 'file audio';
      case MediaContentType.Image:
        return 'file image';
    }
    return 'file video';
  };

  const navigate = useNavigate();
  const viewMedia = () => {
    navigate(props.id + '/view');
  };

  const media = props.media;
  return (
    <Item onClick={viewMedia}>
      <Item.Image size="medium">
        <div className="aspect-ratio-sixteen-nine">
          <img
            src={
              media.thumbnailURL != null
                ? media.thumbnailURL
                : 'https://react.semantic-ui.com/images/wireframe/image.png'
            }
          ></img>
        </div>
      </Item.Image>
      <Item.Content>
        <Item.Header>
          <Icon name={fileIcon(media.mediaContentType)} />
          {media.name}
        </Item.Header>
        <Item.Meta>
          Size: {Math.round(media.size / 10000000) / 100} GB / Type: {media.mediaContentType}
        </Item.Meta>

        <Item.Extra>
          <Label.Group color="grey">
            <Label>Categories: {media?.listCategoryLabels?.length}</Label>
            <Label>Labels: {media?.listLabels?.length}</Label>
            <Label>Tracked Objects: {media?.listTrackedLabels?.length}</Label>
          </Label.Group>
        </Item.Extra>
      </Item.Content>
    </Item>
  );
}
