// @flow
import { useNavigate, useParams } from 'react-router-dom';
import { treeEditorDeserializer, treeEditorSerializer } from '../../types/serializr/serialGraphBuilder';
import MediaVideoPlayer from '../media/MediaVideoPlayer.react';

import React, { useState } from 'react';

import { Button, Container, Header, Segment, Image, Card, Grid } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { wizardGoBackwardsEdit, wizardSetNextEdit } from '../../actions/wizard.react';
import { asyncUpdateGraphToFirestore, asyncWriteTimelineToFirestore } from '../wizard/WizardUtils';
import { useEffect } from 'react';
import { firestoreDB } from '../../firebase/firebase.react';
import { doc, getDoc } from 'firebase/firestore';
import LoadingPage from '../../pages/LoadingPage.react';
import type {
  EditGraphMedia,
} from "../../types/serializr/serialGraphStructure";
import ClipPreview from '../preview/ClipPreview.react';

export default function WizardClipSelector(): React$MixedElement {
  const [isLoading, setIsLoading] = useState(true);
  const { workspaceId, graphId } = useParams();
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    const updateMediaLink = async (MediaNode: EditGraphMedia) => {
      const mediaRef = doc(firestoreDB, `/ws/${workspaceId}/media/${MediaNode.oid}`);
      const mediaSnap = await getDoc(mediaRef);
      const mediaData = mediaSnap.data();
      if (mediaData?.mediaURL != null) {
        MediaNode.mediaURL = mediaData.mediaURL;
      }

      if (mediaData?.thumbnailURL != null) {
        // console.log(MediaNode.oid, ': ', mediaData.thumbnailURL);
        MediaNode.thumbnailURL = mediaData.thumbnailURL;
      }

      return MediaNode;
    };
    const fetch = async () => {
      const wizardRef = doc(firestoreDB, `/ws/${workspaceId}/wizards/${graphId}`);
      const wizardSnap = await getDoc(wizardRef);
      const wizardData = wizardSnap.data();

      const graphRef = wizardData?.videographRef;

      if (wizardSnap.exists && graphRef != null) {
        const graphSnap = await getDoc(graphRef);
        const dataGraphJSON = graphSnap.data();
        if (dataGraphJSON?.buildIndex != null) {
          // Load Graph
          const daGraphData = treeEditorDeserializer(dataGraphJSON);

          let changeCount = 0;

          await Promise.all(
            Object.keys(daGraphData?.media).map(async (mediaKey) => {
              const MediaNode = daGraphData.media[mediaKey];
              if (
                MediaNode?.thumbnailURL === 'temp' ||
                MediaNode?.mediaURL === 'temp' ||
                MediaNode?.thumbnailURL === '' ||
                MediaNode?.mediaURL === ''
              ) {
                changeCount++;
                daGraphData.media[mediaKey] = await updateMediaLink(MediaNode);
              }
            })
          );

          if (changeCount > 0) {
            console.log('MISSING DATA, FILLING');
            const json = treeEditorSerializer(daGraphData);
            await asyncUpdateGraphToFirestore(json, graphRef.path);
          }
          setGraphData(daGraphData);
          setIsLoading(false);
        }
      }
    };

    fetch();
  }, [graphId, workspaceId]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return <WizardClipRender graphData={graphData} />;
}

type RenderProps = {
  graphData: any
};

function WizardClipRender(props: RenderProps): React$MixedElement {
  const { workspaceId } = useParams();
  const graphData = props.graphData;

  // console.log(graphData);
  const [historyCuts, setHistoryCuts] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const graphID = graphData?.oid ?? 'unknown';

  const currentCut = graphData?.cuts?.[currentIndex] ?? null;
  const prevCut: any = historyCuts?.length > 0 ? historyCuts[historyCuts.length - 1] : null;
  const nextCuts =
    graphData
      ?.getNextCuts(currentCut)
      ?.filter((x) => x.length > 5.0)
      ?.slice(0, 4) ?? [];
  const handleClick = (newIndex: number) => {
    const history = [...historyCuts, currentCut];
    setHistoryCuts(history);
    setCurrentIndex(newIndex);
    dispatch(wizardSetNextEdit(graphID, newIndex));
  };

  const handleSave = async () => {
    const snapShot: Array<any> = [...historyCuts, currentCut];
    const docID = await asyncWriteTimelineToFirestore(workspaceId, graphID, snapShot);
    navigate('../../edit/timeline/' + docID);
  };

  const handleBack = () => {
    if (historyCuts.length > 0) {
      const history = [...historyCuts];
      const prev: any = history.pop();
      setHistoryCuts(history);
      setCurrentIndex(prev.index);
      dispatch(wizardGoBackwardsEdit(graphID));
    }
  };

  return (
    <Container fluid className="page-body">
      <Segment>
        <Grid columns={2}>
          <Grid.Column>
            <Header>Current Video Timeline</Header>
          </Grid.Column>
          <Grid.Column>
            <Button
              labelPosition="left"
              content="Create Video"
              icon="download"
              floated="right"
              positive
              disabled={currentIndex === 0}
              onClick={handleSave}
            />
          </Grid.Column>
        </Grid>
        <div className="collection-viewport">
          <Card.Group>
            {historyCuts?.map((child, idx) => (
              <Card key={idx} className="collection-card">
                <Image src={child?.media?.thumbnailURL} className="card-img-fill" />
                <Card.Description textAlign="center">{child.index}</Card.Description>
              </Card>
            ))}
            <Card color="yellow">
              <Image src={currentCut?.media?.thumbnailURL} className="card-img-fill" />
              <Card.Description textAlign="center">{currentCut.index}</Card.Description>
            </Card>
          </Card.Group>
        </div>
      </Segment>
      <Segment>
        <Grid columns={2}>
          <Grid.Column>
            <Header>Previous Clip {prevCut?.index}</Header>
            <MediaVideoPlayer
              thumbnailURL={null}
              mediaURL={prevCut?.media?.mediaURL}
              mediaURLFallback={null}
              selectedClip={prevCut?.getPlayerData()}
            />
          </Grid.Column>
          <Grid.Column>
            <Header>Selected Clip {currentCut.index}</Header>
            <MediaVideoPlayer
              thumbnailURL={null}
              mediaURL={currentCut?.media?.mediaURL}
              mediaURLFallback={null}
              selectedClip={currentCut?.getPlayerData()}
            />
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment>
        <Header>Next Clip</Header>
        <Grid stackable>
          <Grid.Column width={3}>
            <Card className="collection-card">
              <Button
                content="Autoselect"
                size="huge"
                labelPosition="right"
                icon="right arrow"
                onClick={() => handleClick(nextCuts?.[0]?.index)}
                disabled={!nextCuts?.[0]?.index}
                positive
                className="fill-height"
              />
            </Card>
            <Card className="collection-card">
              <Button
                content="Back"
                size="huge"
                icon="left arrow"
                labelPosition="left"
                onClick={handleBack}
                disabled={currentIndex === 0}
                negative
                className="fill-height"
              />
            </Card>
          </Grid.Column>
          <Grid.Column width={13}>
            <Card.Group doubling itemsPerRow={4}>
              {nextCuts.map((child, idx) => (
                <Card onClick={() => handleClick(child?.index)} key={idx} link>
                  <ClipPreview clip={child?.getPlayerData()} />
                  {/* <Image src={child?.media?.thumbnailURL} className="card-img-fill" /> */}
                  <Button primary>
                    Select Clip {child?.index}: {Math.round(child?.length)}s
                  </Button>
                </Card>
              ))}
            </Card.Group>
          </Grid.Column>
        </Grid>
      </Segment>
    </Container>
  );
}
