// @flow
import WizardDetails from '../components/wizard/WizardDetails.react';

import React from 'react';
import { useEffect, useState } from 'react';

import { firestoreDB } from '../firebase/firebase.react';
import { collection, getDocs, limit, query } from 'firebase/firestore';

import { Container, Segment, Grid } from 'semantic-ui-react';
import WizardCreator from '../components/config/WizardCreator.react';
import TemplateList from '../components/template/TemplateList.react';
import { useParams } from 'react-router-dom';

type Props = {};

export default function WizardStartingPoint(props: Props): React$MixedElement {
  const { workspaceId } = useParams();
  const [wizards, setWizards] = useState();
  useEffect(() => {
    const fetchCollections = async () => {
      const collectPath = '/ws/' + workspaceId + '/wizards/';

      const qLimit = query(collection(firestoreDB, collectPath), limit(5));
      const fetchedDocs = await getDocs(qLimit);
      const listCollection = fetchedDocs.docs;
      setWizards(listCollection);
    };
    fetchCollections();
  }, [workspaceId]);

  return (
    <Container>
      <Segment placeholder>
        <WizardCreator />
      </Segment>
      <Grid stackable padded="vertically" columns={2}>
        <Grid.Column>
          <Segment>
            <WizardDetails listWizards={wizards} />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <TemplateList />
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
