// @flow
import * as React from 'react';
import type { typeUser } from '../types/typeUser';
import { initUser } from '../types/typeUser';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { firestoreDB } from '../firebase/firebase.react';
import { connect } from 'react-redux';

type firebaseContextType = React$Context<{
  actionUpdateUser: (user: typeUser) => void,
  authStatusReported: boolean,
  isUserSignedIn: boolean,
  user: typeUser,
  ...
}>;

const defaultfirebaseContext = {
  user: initUser,
  actionUpdateUser: (user: typeUser) => {},
  authStatusReported: false,
  isUserSignedIn: false
};

export const FirebaseUserContext: firebaseContextType = React.createContext(defaultfirebaseContext);

type FireUserProviderProps = {
  children: React.Node,
  dispatch: any
};

export type FireUserProviderState = {
  user: typeUser,
  authStatusReported: boolean,
  isUserSignedIn: boolean
};

class FirebaseUserProvider extends React.Component<FireUserProviderProps, FireUserProviderState> {
  // Action Events
  actionUpdateUser = (user: typeUser) => {
    const userDoc = doc(firestoreDB, 'users', user.uid);
    setDoc(userDoc, user, { merge: true });
  };

  state: any = {
    actionUpdateUser: this.actionUpdateUser,
    authStatusReported: false,
    user: initUser,
    isUserSignedIn: false
  };

  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.setState({
        user: user,
        authStatusReported: true,
        isUserSignedIn: !!user
      });
    });
  }

  render(): React.Node {
    const { children } = this.props;
    const { authStatusReported } = this.state;

    return (
      <FirebaseUserContext.Provider value={{ ...this.state }}>
        {authStatusReported && children}
      </FirebaseUserContext.Provider>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default (connect(mapStateToProps)(FirebaseUserProvider): any);
