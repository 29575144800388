// @flow

import WorkspaceList from '../components/workspace/WorkspaceList.react';

import React, { useEffect, useState } from 'react';
import { collection, getDocs, where, query } from 'firebase/firestore';
import { firestoreDB } from '../firebase/firebase.react';
import { useContext } from 'react';
import { FirebaseUserContext } from '../providers/FirebaseUserProvider.react';
import type { typeWorkspaceSnap } from '../types/typeWorkspace';

export default function WorkspaceListPage(): React$MixedElement {
  const [workspaces, setWorkspaces] = useState<typeWorkspaceSnap[]>([]);
  const { user } = useContext(FirebaseUserContext);

  useEffect(() => {
    const fetchCollections = async () => {
      const workspaceColRef = collection(firestoreDB, '/ws/');
      // const queryDocs = query(workspaceColRef, where('userAccessList', 'array-contains', user.uid));
      const fetchedDocs = await getDocs(workspaceColRef);
      const listCollection = fetchedDocs.docs;
      setWorkspaces(listCollection);
    };
    fetchCollections();
  }, [user.uid]);
  return <WorkspaceList snap={workspaces} />;
}
