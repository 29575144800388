// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Header, Segment, Image, List, Grid, Button } from 'semantic-ui-react';

export default function HomePage(): React$MixedElement {
  return (
    <Container fluid className="page-body">
      <Segment placeholder>
        <Header textAlign="center" size="large">
          MemoryFlow Video Creator
        </Header>
        <div style={{ margin: '0 auto' }}>
          <Button.Group>
            <Button as={Link} to="/ws/" primary>
              Your Workspaces
            </Button>
          </Button.Group>
        </div>
      </Segment>
      <Container>
        <Grid stackable padded>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Segment size="large" padded>
                <Header dividing>Why use MemoryFlow to create videos?</Header>
                Use MemoryFlow to intelligently organizes and edit videos for you. Simply upload your footage,
                and create customized videos. MemoryFlow finds new ways to cut your footage together. It makes
                it fast and easy to create video montages, reels/shorts, or highlight videos.
                <Header size="small">Automatic Editing</Header>
                Intergrate MemoryFlow to create assets for your existing workflows, MemoryFlow can create
                captivating cuts with the right tweaks.
                <Image />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Segment size="large" padded>
                <Header>Feature List</Header>
                <List bulleted>
                  <List.Item>Easily and quickly create videos to share.</List.Item>
                  <List.Item>Automatically finds footage and suggestions edits.</List.Item>
                  <List.Item>Customizable edit styles and create reusable templates.</List.Item>
                  <List.Item>Use context hidden within the videos.</List.Item>
                  <List.Item>Work in parallel and render in parallel.</List.Item>
                </List>
                <Image />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Container>
  );
}
