import { CHANGE_PAGE } from '../actions/'

export default (
  state = {
    currentPage: 'home',
  },
  action
) => {
  switch (action.type) {
    case CHANGE_PAGE:
      return {
        ...state,
        isEdit: action.isEdit,
        isEditor: action.isEditor,
        currentPage: action.currentPage
      }
    default:
      return state
  }
}