// @flow
import type { typeWorkspace } from '../../types/typeWorkspace';
import { FirebaseUserContext } from '../../providers/FirebaseUserProvider.react';

import React, { useContext, useState } from 'react';
import { Button, Container, Header, Segment, Form } from 'semantic-ui-react';
import { collection, addDoc } from 'firebase/firestore';
import { firestoreDB } from '../../firebase/firebase.react';
import { initWorkspace } from '../../types/typeWorkspace';
import { useNavigate } from 'react-router-dom';

export default function WorkspaceCreation(): ?React$MixedElement {
  const { user } = useContext(FirebaseUserContext);
  const navigate = useNavigate();

  const [workspace, setWorkspace] = useState<typeWorkspace>(initWorkspace);
  const [error, setError] = useState<string[]>([]);

  const createWorkspace = async () => {
    setError([]);
    const workspaceRef = collection(firestoreDB, 'ws');
    try {
      if (!workspace.title || workspace.title === '') {
        setError([...error, 'title']);
        return;
      }
      if (!workspace.description || workspace.description === '') {
        setError([...error, 'description']);
        return;
      }

      workspace.createdBy = user.uid;
      workspace.userAccessList = [user.uid];
      const newDoc = await addDoc(workspaceRef, workspace);
      navigate(`/ws/${newDoc.id}/media`);
      console.log('Workspace created successfully!');
    } catch (error) {
      console.error('Error creating workspace:', error);
    }
  };

  return (
    <Container>
      <Segment placeholder>
        <Form>
          {error.length > 0 && <Header as="h3">Please fill in the required fields</Header>}
          <Form.Input
            label="Workspace Name"
            placeholder="Workspace Name"
            name="title"
            value={workspace.title}
            onChange={(e) => setWorkspace({ ...workspace, title: e.target.value })}
            className="reset-max-width"
          />
          {error.length > 0 && <Header as="h3">Please fill in the required fields</Header>}
          <Form.Input
            label="Workspace Description"
            placeholder="Workspace Description"
            name="description"
            value={workspace.description}
            onChange={(e) => setWorkspace({ ...workspace, description: e.target.value })}
            className="reset-max-width"
          />
          <input label="Workspace Owner" placeholder={user.displayName} style={{ display: 'none' }} />
          <Button onClick={createWorkspace}> Create Workspace </Button>
        </Form>
      </Segment>
    </Container>
  );
}
