/* eslint-disable no-unused-vars */
// @flow

import { asyncFindCSVDataset, asyncWriteWizardToFirestore } from '../wizard/WizardUtils';

import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestoreDB } from '../../firebase/firebase.react';

import React, { useEffect, useState } from 'react';
import { Container, Button, Grid, Header, Form, Icon } from 'semantic-ui-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { defaultConfigForWizard } from '../../types/utils/wizardUtils';
import { fasyncVideoGraphCallable } from '../../firebase/firebase.react';
import WizardSearchBar from '../wizard/WizardSearchBar.react';
import type { TWizardURLObject } from '../../types/utils/wizardTypeUtils';
import type { typeTemplate } from '../../types/typeTemplate';
import wizard from '../../reducers/wizard';
type Props = {};

export default function WizardSelector(props: Props): React$MixedElement {
  const { workspaceId } = useParams();
  defaultConfigForWizard.workspaceId = workspaceId ?? 'test';
  const [wizardConfigData, setFormData] = useState(defaultConfigForWizard);
  // eslint-disable-next-line no-unused-vars
  const [errorData, setErrorData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [templateList, setTemplateList] = useState<Array<typeTemplate>>([]);

  const navigate = useNavigate();

  console.log('WizardSelector: ', wizardConfigData);

  const templateOptions =
    templateList?.length > 0
      ? templateList.map((template) => {
          return { key: template.id, text: template.templateName, value: template.id };
        })
      : [{ key: 0, text: 'Unknown', value: 'roZDEQhwcbX5NJtMf0Yh' }];

  useEffect(() => {
    // console.log('query TemplateList', workspaceId, filterList);
    const docPath = '/ws/' + workspaceId + '/templates/';
    const targetCollection = collection(firestoreDB, docPath);
    let q = query(targetCollection);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const mapFiles = [];
      querySnapshot.forEach((doc) => {
        mapFiles.push({ id: doc.id, ...doc.data() });
      });
      setTemplateList(mapFiles);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  const handleChange = (name: string, value: any) => {
    console.log(name, value);
    setFormData({ ...wizardConfigData, [name]: value });
    if (name === 'templateId') {
      setErrorData(null);
    }
  };

  const validateForm = () => {
    if (wizardConfigData.templateId == null) {
      setErrorData({ ...errorData, templateId: true });
      return false;
    } else {
      setErrorData(null);
      return true;
    }
  };

  const handleSubmit = async (data: any) => {
    setIsLoading(!isLoading);
    const isValid = validateForm();
    if (isValid) {
      // wizardConfigData.startTimeClipID = '0fd16f0f4aee8b495fa9146e200165a4';
      // wizardConfigData.startTimeEntityName = 'building';
      // wizardConfigData.startTimeMediaID = 'BKzJEKigKSnUXRhe1qZl';
      const json = await asyncFindCSVDataset(workspaceId);
      const docRef = await asyncWriteWizardToFirestore(workspaceId, wizardConfigData, json);
      navigate('editor/' + docRef.id);
    }
  };

  const configLink = wizardConfigData.templateId ? 'config/' + wizardConfigData.templateId : 'config';

  return (
    <Container textAlign="center">
      <Form onSubmit={handleSubmit}>
        <Grid padded centered doubling>
          <Grid.Row>
            <Grid.Column moblie={16} tablet={12} computer={8}>
              <Header size="huge" textAlign="center" icon>
                <Icon name="film" />
              </Header>
              <Form.Group className="justify-center fake-header" inline>
                <Form.Field>Create a new video using &nbsp;</Form.Field>
                <Form.Field>
                  <Form.Dropdown
                    fluid
                    inline
                    name="templateId"
                    options={templateOptions}
                    value={wizardConfigData?.templateId}
                    placeholder="Select Template"
                    onChange={(_, v) => handleChange(v.name, v.value)}
                    error={errorData?.templateId}
                  />
                </Form.Field>
              </Form.Group>
              <WizardSearchBar
                name="queryLabelEntityName"
                placeholder="Filter Footage"
                onChange={handleChange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button.Group>
                <Button as={Link} to={configLink}>
                  Customize
                </Button>
                <Button loading={isLoading} primary>
                  Generate New
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Container>
  );
}
