// @flow

import type { fragmentClip } from '../../types/fragments/fragmentClip';
import type { typeMedia } from '../../types/typeMedia';

import ClipListItem from './ClipListItem.react';

import React from 'react';
import { useMemo } from 'react';
import { List } from 'semantic-ui-react';

type Props = { clips: Array<fragmentClip>, mediaData: typeMedia };

export default function ClipList(props: Props): React$MixedElement {
  const arrayClips = useMemo(
    () =>
      props.clips.map((clip, idx) => ({
        ...props.mediaData?.clipObject,
        ...clip
      })),
    [props.clips, props.mediaData]
  );

  const clipFromMediaData: fragmentClip = {
    ...mediaData,
    ...mediaData?.clipObject,
    workspaceRefId: props.mediaData?.workspaceRefId ?? 'unknown',
    mediaRefId: props.mediaData?.mediaRefId ?? 'unknown',
    id: props.mediaData?.id ?? 'unknown',
    title: props.mediaData.name,
    thumbnailURL: props.mediaData?.thumbnailURL ?? null,
    length: props.mediaData?.mediaDuration ?? 0,
    mediaDuration: props.mediaData?.mediaDuration ?? 0,
    source: 'media',
    start: 0,
    spriteSheetURL: props.mediaData?.clipObject?.spriteSheetURL ?? null,
    end: props.mediaData?.mediaDuration ?? 0
  };

  return (
    <List divided selection verticalAlign="middle">
      <ClipListItem isActive={false} clipItem={clipFromMediaData} onClick={() => {}} />
      {arrayClips?.map((clip, idx) => (
        <ClipListItem key={idx} isActive={false} clipItem={clip} onClick={() => {}} />
      ))}
    </List>
  );
}
