/* eslint-disable no-unused-vars */
// @flow
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useState } from 'react';
import { Accordion, Container, Segment, Grid, Header, Form, Icon } from 'semantic-ui-react';
import {
  asyncWritePlaceholderGraphToFirestore,
  startFormOptions,
  lookFormOptions,
  editFormOptions,
  durationFormOptions,
  searchLabelEntityOptions
} from '../wizard/WizardUtils';
import { defaultConfigForWizard } from '../../types/utils/wizardUtils';
import type { TWizardConfig } from '../../types/utils/wizardTypeUtils';
import { asyncUpsertTemplateData } from './TemplateUtils';

type Props = {
  defaultTemplate: TWizardConfig,
  onChange?: (e: any, { name: string, value: string }) => void
};

export default function TemplateEditor(props: Props): React$MixedElement {
  const [queryConfig, setQueryConfig] = useState(props.defaultTemplate);
  // eslint-disable-next-line no-unused-vars
  const [errorData, setErrorData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [enableAdv, setEnableAdv] = useState(true);

  const { workspaceId } = useParams();

  const navigate = useNavigate();
  const toggleAdv = () => setEnableAdv(!enableAdv);
  const handleChange = (e: any, { name, value }: { name: string, value: string }) => {
    setQueryConfig({ ...queryConfig, [name]: value });
    if (props?.onChange) {
      props.onChange({}, { name, value });
      // console.log(queryConfig);
    }
  };

  const isNew = queryConfig?.templateId == null;

  const handleSubmit = async () => {
    setIsLoading(true);
    await asyncUpsertTemplateData(workspaceId, queryConfig);
    setIsLoading(false);
  };

  return (
    <Form>
      {/* <Form.Button content="Generate New Video" onClick={handleSubmit} primary /> */}
      <Segment>
        <Grid centered stackable>
          <Grid.Row stretched columns={2}>
            <Grid.Column>
              <Header>Template Name</Header>
              <Form.Input
                fluid
                name="templateName"
                value={queryConfig?.templateName ?? ''}
                className="reset-max-width"
                onChange={handleChange}
                error={errorData?.name}
                action={{
                  primary: true,
                  loading: isLoading,
                  onClick: handleSubmit,
                  content: isNew ? 'Create New' : 'Save Changes'
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched columns={4}>
            <Grid.Column>
              <Segment>
                <Header textAlign="center">Start</Header>
                <Form.Select
                  fluid
                  label="Start Presets"
                  name="startPreset"
                  value={queryConfig?.startPreset ?? ''}
                  options={startFormOptions}
                  className="reset-max-width"
                  onChange={handleChange}
                  error={errorData?.template}
                />
                <Accordion>
                  <Accordion.Title active={enableAdv} onClick={toggleAdv} index={0}>
                    <Icon name="dropdown" />
                    Advanced Setting: Start
                  </Accordion.Title>
                  <Accordion.Content active={enableAdv}>
                    <Form.Input
                      fluid
                      label="Start: Target Name"
                      name="startPosEntityName"
                      value={queryConfig?.startPosEntityName ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                    <Form.Input
                      fluid
                      label="Start: Top Offset"
                      name="startPosX"
                      value={queryConfig?.startPosX ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                    <Form.Input
                      fluid
                      label="Start: Left Offset %"
                      name="startPosY"
                      value={queryConfig?.startPosY ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                    <Form.Input
                      fluid
                      label="Start: Size %"
                      name="startPosSize"
                      value={queryConfig?.startPosSize ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header textAlign="center"> Look</Header>
                <Form.Select
                  fluid
                  label="Look Presets"
                  name="lookPreset"
                  value={queryConfig?.lookPreset ?? ''}
                  options={lookFormOptions}
                  className="reset-max-width"
                  onChange={handleChange}
                  error={errorData?.template}
                />
                <Accordion>
                  <Accordion.Title active={enableAdv} onClick={toggleAdv} index={0}>
                    <Icon name="dropdown" />
                    Advanced Settings: Look
                  </Accordion.Title>
                  <Accordion.Content active={enableAdv}>
                    <Form.Input
                      fluid
                      label="Look: Position Threshold %"
                      name="lookPositionMatch"
                      value={queryConfig?.lookPositionMatch ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                    <Form.Input
                      fluid
                      label="Look: Position Weight %"
                      name="lookPositionWeight"
                      value={queryConfig?.lookPositionWeight ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                    <Form.Input
                      fluid
                      label="Look: Color Weight %"
                      name="lookContextWeight"
                      value={queryConfig?.lookContextWeight ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                    <Form.Input
                      fluid
                      label="Look: Chronological Weight %"
                      name="lookTemporalWeight"
                      value={queryConfig?.lookTemporalWeight ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header textAlign="center"> Edit Style</Header>
                <Form.Select
                  fluid
                  label="Edit Style Preset"
                  name="editPreset"
                  value={queryConfig?.editPreset ?? ''}
                  options={editFormOptions}
                  className="reset-max-width"
                  onChange={handleChange}
                  error={errorData?.template}
                />
                <Accordion>
                  <Accordion.Title active={enableAdv} onClick={toggleAdv} index={0}>
                    <Icon name="dropdown" />
                    Advanced Settings: Edit
                  </Accordion.Title>
                  <Accordion.Content active={enableAdv}>
                    <Form.Input
                      fluid
                      label="Edit: Search Start (s)"
                      name="editSearchTime"
                      value={queryConfig?.editSearchTime ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                    <Form.Input
                      fluid
                      label="Edit: Clip Min Duration (s)"
                      name="editLengthMin"
                      className="reset-max-width"
                      value={queryConfig?.editLengthMin ?? ''}
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                    <Form.Input
                      fluid
                      label="Edit: Clip Max Duration (s)"
                      name="editLengthMax"
                      value={queryConfig?.editLengthMax ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header textAlign="center"> Length</Header>
                <Form.Select
                  fluid
                  label="Preset Length"
                  name="durationPreset"
                  value={queryConfig?.durationPreset ?? ''}
                  options={durationFormOptions}
                  className="reset-max-width"
                  onChange={handleChange}
                  error={errorData?.template}
                />
                <Accordion>
                  <Accordion.Title active={enableAdv} onClick={toggleAdv} index={0}>
                    <Icon name="dropdown" />
                    Advanced Settings: Length
                  </Accordion.Title>
                  <Accordion.Content active={enableAdv}>
                    <Form.Input
                      fluid
                      label="Length: Target (s)"
                      name="durationTargetTime"
                      value={queryConfig?.durationTargetTime ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                    <Form.Input
                      fluid
                      label="Length: Min (s)"
                      name="durationMinTime"
                      value={queryConfig?.durationMinTime ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                    <Form.Input
                      fluid
                      label="Length: Max (s)"
                      name="durationMaxTime"
                      value={queryConfig?.durationMaxTime ?? ''}
                      className="reset-max-width"
                      onChange={handleChange}
                      error={errorData?.template}
                    />
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Form>
  );
}
