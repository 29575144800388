// @flow
import AccessDenied from './AccessDenied.react';

import React from 'react';
import { Outlet } from 'react-router-dom';
type Props = {
  children?: any,
  isAuthenticated: boolean,
  isVerifying: boolean
};
const ProtectedRoute = (props: Props): React$MixedElement => {
  if (!props.isAuthenticated) {
    return <AccessDenied />;
  }

  return props.children ? props.children : <Outlet />;
};

export default ProtectedRoute;
