// @flow

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, query, orderBy, startAt, limit } from 'firebase/firestore';
import { firestoreDB } from '../../firebase/firebase.react';

import { Item } from 'semantic-ui-react';

const MediaFindImage = (props: { workspaceId: string }): React$MixedElement => {
  // Your component logic goes here
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);

  // const { workspaceId } = useParams(); // Get the workspace id from React Router params

  function getRandomIndex(count: number) {
    return Math.floor(Math.random() * count);
  }

  const fetchRandomDocument = async () => {
    setLoading(true);
    const collectionName = `/ws/${props.workspaceId}/media`; // Replace with your collection name
    const randomIndex = getRandomIndex(3);

    const q = query(collection(firestoreDB, collectionName), orderBy('updatedAt', 'desc'), limit(4));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const media = querySnapshot.docs?.[randomIndex]?.data() ?? querySnapshot.docs[0].data();
      setDocument(media);
    } else {
      setDocument(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRandomDocument();
  }, [props.workspaceId]);

  return (
    <Item.Image size="medium">
      <div className="aspect-ratio-sixteen-six">
        {document?.thumbnailURL && <img src={document.thumbnailURL} alt="Document Thumbnail" />}
      </div>
    </Item.Image>
  );
};

export default MediaFindImage;
