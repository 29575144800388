// @flow

import React, { useEffect, useState } from 'react';
import { Item } from 'semantic-ui-react';
import MediaListItem from './MediaListItem.react';
import { onSnapshot, query, collection, orderBy, where, limit } from 'firebase/firestore';
import { firestoreDB } from '../../firebase/firebase.react';
import { useParams, useSearchParams } from 'react-router-dom';
import type { typeMedia } from '../../types/typeMedia';

type Props = {
  filterList?: Array<?string>
};

export default function MediaList(props: Props): React$MixedElement {
  const { workspaceId } = useParams();
  const [searchParams] = useSearchParams();
  const [mediaList, setMediaList] = useState<typeMedia[]>([]);

  const filterList = searchParams.getAll('search');

  useEffect(() => {
    // console.log('query MediaList', workspaceId, filterList);
    const docPath = '/ws/' + workspaceId + '/media/';
    const targetCollection = collection(firestoreDB, docPath);
    let q = query(targetCollection, orderBy('updatedAt', 'desc'), limit(25));
    if (filterList && filterList.length > 0) {
      q = query(targetCollection, where('listTrackedLabels', 'array-contains-any', filterList));
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const mapFiles: typeMedia[] = [];
      querySnapshot.forEach((doc) => {
        mapFiles.push({ id: doc.id, ...doc.data() });
      });
      setMediaList(mapFiles);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterList), workspaceId]);

  return (
    <Item.Group link>
      {mediaList.map((file, idx) => (
        <MediaListItem key={idx} id={file?.id ?? '0'} media={file} />
      ))}
    </Item.Group>
  );
}

// // @flow

// import React, { useEffect, useState } from 'react';
// import { Item, Button } from 'semantic-ui-react';
// import MediaListItem from './MediaListItem.react';
// import { onSnapshot, query, collection, orderBy, limit, where, startAfter } from 'firebase/firestore';
// import { firestoreDB } from '../../firebase/firebase.react';
// import { useParams, useSearchParams } from 'react-router-dom';
// import type { typeMedia } from '../../types/typeMedia';

// type Props = {};

// export default function MediaList(props: Props): React$MixedElement {
//   const { workspaceId } = useParams();
//   const [searchParams] = useSearchParams();
//   const [mediaList, setMediaList] = useState<typeMedia[]>([]);
//   const [lastVisible, setLastVisible] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);

//   const filterList = searchParams.getAll('search');

//   const fetchMediaList = (startAfterDoc: ?string = null) => {
//     setIsLoading(true);
//     const docPath = '/ws/' + workspaceId + '/media/';
//     const targetCollection = collection(firestoreDB, docPath);
//     let q = query(targetCollection, orderBy('updatedAt', 'desc'), limit(10));

//     if (filterList && filterList.length > 0) {
//       q = query(
//         targetCollection,
//         where('listTrackedLabels', 'array-contains-any', filterList),
//         orderBy('updatedAt', 'desc'),
//         limit(10)
//       );
//     }

//     if (startAfterDoc) {
//       q = query(q, startAfter(startAfterDoc));
//     }

//     const unsubscribe = onSnapshot(q, (querySnapshot) => {
//       const mapFiles: typeMedia[] = [];
//       querySnapshot.forEach((doc) => {
//         mapFiles.push({ id: doc.id, ...doc.data() });
//       });

//       if (querySnapshot.docs.length > 0) {
//         setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
//       }

//       setMediaList((prevList) => [...prevList, ...mapFiles]);
//       setIsLoading(false);
//     });

//     return () => {
//       unsubscribe();
//     };
//   };

//   useEffect(() => {
//     setMediaList([]);
//     setLastVisible(null);
//     fetchMediaList();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [JSON.stringify(filterList), workspaceId]);

//   return (
//     <>
//       <Item.Group link>
//         {mediaList.map((file, idx) => (
//           <MediaListItem key={idx} id={file?.id ?? '0'} media={file} />
//         ))}
//       </Item.Group>
//       <Button onClick={() => fetchMediaList(lastVisible)} disabled={isLoading}>
//         {isLoading ? 'Loading...' : 'Load More'}
//       </Button>
//     </>
//   );
// }
