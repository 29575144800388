// @flow
import type { fragmentClip } from '../../types/fragments/fragmentClip';
import React from 'react';
import ReactResponsiveSpritesheet from 'react-responsive-spritesheet';
import { Image } from 'semantic-ui-react';

type Props = {
  clip: fragmentClip
};

export default function ClipPreview(props: Props): React$MixedElement {
  const clip = props.clip;
  if (clip?.spriteSheetURL) {
    let startAt = 0;
    let endAt = 100;

    if (clip.start && clip.end) {
      const start = clip.start;
      const end = clip.end;
      const length = clip.mediaDuration ?? 100;
      startAt = Math.floor((start / length) * 100);
      endAt = Math.floor((end / length) * 100);
    }

    return (
      <ReactResponsiveSpritesheet
        image={clip.spriteSheetURL}
        widthFrame={clip.spriteSheetWidth ?? 250}
        heightFrame={clip.spriteSheetHeight ?? 140}
        isResponsive={true}
        steps={100}
        fps={6}
        autoplay={false}
        loop={true}
        startAt={startAt}
        endAt={endAt}
        onMouseEnter={(spritesheet) => {
          spritesheet.play();
        }}
        onMouseLeave={(spritesheet) => {
          spritesheet.pause();
        }}
        background={clip.thumbnailURL}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center center"
      />
    );
  }
  return <Image src={clip.thumbnailURL} />;
}
