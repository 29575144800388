// @flow

// import type { typeWorkspace } from '../../types/typeWorkspace';
// import type { DocumentSnapshot } from '../../types/utils/firebaseTypes';

import React from 'react';
import { Menu } from 'semantic-ui-react';
import { NavLink, useParams } from 'react-router-dom';

// type Props = $ReadOnly<{
// }>;

export default function WorkspaceMenu(): React$MixedElement {
  const { workspaceId } = useParams();
  if (workspaceId != null) {
    return (
      <>
        <Menu.Item name="media" as={NavLink} to={`/ws/${workspaceId}/media`} />
        <Menu.Item name="create" as={NavLink} to={`/ws/${workspaceId}/create`} />
        <Menu.Item name="edit" as={NavLink} to={`/ws/${workspaceId}/edit`} />
      </>
    );
  }

  return (
    <>
      <Menu.Item name="workspaces" as={NavLink} to={`/ws`} />
    </>
  );
}
