// @flow
import React from 'react';
import { Segment, Dimmer, Loader, Image, Container, Header } from 'semantic-ui-react';

function LoadingPage(): React$MixedElement {
  return (
    <Container>
      <Header>Loading...</Header>

      <Segment>
        <Dimmer active inverted>
          <Loader inverted content="Loading" />
        </Dimmer>

        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    </Container>
  );
}

export default LoadingPage;
