/* eslint-disable no-unused-vars */
// @flow
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useState } from 'react';
import { Accordion, Container, Segment, Grid, Header, Form, Icon } from 'semantic-ui-react';
import {
  asyncWritePlaceholderGraphToFirestore,
  startFormOptions,
  lookFormOptions,
  editFormOptions,
  durationFormOptions,
  searchLabelEntityOptions
} from '../wizard/WizardUtils';
import { defaultConfigForWizard } from '../../types/utils/wizardUtils';
import TemplateEditor from '../template/TemplateEditor.react';

type Props = {};

export default function WizardCustomizer(props: Props): React$MixedElement {
  const { workspaceId } = useParams();
  const [queryConfig, setQueryConfig] = useState(defaultConfigForWizard);
  // eslint-disable-next-line no-unused-vars
  const [errorData, setErrorData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [enableAdv, setEnableAdv] = useState(true);

  const navigate = useNavigate();

  const handleChange = (e: any, { name, value }: { name: string, value: string }) => {
    setQueryConfig({ ...queryConfig, [name]: value });
    // console.log(queryConfig);
  };

  const toggleAdv = () => setEnableAdv(!enableAdv);

  const handleSubmit = async () => {
    setIsLoading(true);
    const docRef = await asyncWritePlaceholderGraphToFirestore(workspaceId, queryConfig);
    queryConfig.wizardID = docRef.id;
    queryConfig.wizardPath = docRef.path;

    navigate(docRef.id);
  };

  return (
    <Container textAlign="center">
      <Header size="huge" icon>
        Customizer
      </Header>
      <Form>
        {/* <Form.Button content="Generate New Video" onClick={handleSubmit} primary /> */}

        <Segment>
          <Grid centered stackable>
            <Grid.Row stretched columns={2}>
              <Grid.Column>
                <Header>Add Filters?</Header>
                <Form.Dropdown
                  fluid
                  multiple
                  search
                  selection
                  inline
                  className="reset-max-width"
                  name="queryLabelEntityName"
                  icon="search"
                  options={searchLabelEntityOptions}
                  value={queryConfig.queryLabelEntityName}
                  onChange={handleChange}
                  error={errorData?.queryLabelEntityName}
                />
              </Grid.Column>
              <Grid.Column>
                <Header>Template Name</Header>
                <Form.Input
                  fluid
                  name="name"
                  value={queryConfig.name ?? ''}
                  className="reset-max-width"
                  onChange={handleChange}
                  error={errorData?.name}
                  action={{
                    primary: true,
                    onClick: handleSubmit,
                    content: 'Save Changes'
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Form>
      <Container>
        <TemplateEditor defaultTemplate={defaultConfigForWizard} onChange={handleChange} />
      </Container>
    </Container>
  );
}
