// @flow

import type { typeWorkspace } from '../../types/typeWorkspace';
import type { DocumentSnapshot } from '../../types/utils/firebaseTypes';

import React from 'react';
import { Item, Icon, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import MediaFindImage from '../thumbnail/ThumbnailFindImage.react';

type Props = $ReadOnly<{
  snap: DocumentSnapshot<typeWorkspace>
}>;

export default function WorkspaceListItem(props: Props): React$MixedElement {
  const snapshot = props.snap;
  const data = snapshot.data();
  return (
    <Item background="gray">
      <MediaFindImage workspaceId={snapshot.id} />
      <Item.Content>
        <Item.Header as={Link} to={`${snapshot.id}/media`}>
          {data.title}
        </Item.Header>

        <Item.Extra>
          <List>
            <List.Item>
              <Icon color="grey" name="video" /> {data.countMedia} Media Files
            </List.Item>
            <List.Item>
              <Icon color="grey" name="box" /> {data.countClips} Clips
            </List.Item>
            <List.Item>
              <Icon color="grey" name="lemon" /> Shared with {(data?.userAccessList?.length ?? 1) - 1} People
            </List.Item>
          </List>
        </Item.Extra>
      </Item.Content>
    </Item>
  );
}
