// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from './actions';
import { Menu, Icon } from 'semantic-ui-react';
import WorkspaceMenu from './components/workspace/WorkspaceMenu.react';
import UploadManager from './components/upload/UploadManager.react';

type Props = {
  dispatch: any,
  location: any,
  isAuthenticated: boolean,
  isVerifying: boolean,
  currentPage: string
};

class AppMenu extends Component<Props> {
  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch(logoutUser());
  };

  render(): React$MixedElement {
    return (
      <div>
        <Menu compact fixed="top">
          <Menu.Menu>
            <Menu.Item name="home" as={Link} to="/">
              <Icon name="eye" /> MemoryFlow
            </Menu.Item>
            <WorkspaceMenu />
          </Menu.Menu>
          <Menu.Menu position="right">
            {this.props.isAuthenticated ? (
              <Menu.Item>
                <UploadManager />
              </Menu.Item>
            ) : null}

            {this.props.isAuthenticated ? (
              <Menu.Item icon="user" as={Link} to="#" onClick={this.handleLogout} />
            ) : (
              <Menu.Item content="Sign In" as={Link} to="/login" icon="user outline" />
            )}
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
    isVerifying: state.auth?.isVerifying
  };
}

export default (connect(mapStateToProps)(AppMenu): any);
