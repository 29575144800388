// @flow

import { provider, firestoreDB } from '../firebase/firebase.react';
import { doc, setDoc } from 'firebase/firestore';
import { getAuth, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import type { typeUser } from '../types/typeUser';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ANON = 'LOGIN_ANON';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST
  };
};

const receiveLogin = (user: any) => {
  return {
    type: LOGIN_SUCCESS,
    user
  };
};

const loginError = () => {
  return {
    type: LOGIN_FAILURE
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS
  };
};

export const loginGoogle = (): ((dispatch: any) => void) => (dispatch: any) => {
  dispatch(requestLogin());
  const auth = getAuth();
  signInWithPopup(auth, provider)
    .then((result) => {
      console.log('loginGoogle', result);
      const user: typeUser = {
        creationTime: result.user.metadata.creationTime,
        displayName: result.user.displayName,
        email: result.user.email,
        emailVerified: result.user.emailVerified,
        photoURL: result.user.photoURL,
        providerId: 'google',
        uid: result.user.uid,
        createdBy: result.user.uid,
        lastSignInTime: result.user.metadata.lastSignInTime,
      };
      const userDoc = doc(firestoreDB, 'users', result.user.uid);
      setDoc(userDoc, user, { merge: true });

      dispatch(receiveLogin(result));
    })
    .catch(() => {
      dispatch(loginError());
    });
};

export const logoutUser = (): ((dispatch: any) => void) => (dispatch: any) => {
  dispatch(requestLogout());
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      dispatch(receiveLogout());
    })
    .catch(() => {
      // Do something with the error if you want!
      dispatch(logoutError());
    });
};

export const verifyAuth = (): ((dispatch: any) => void) => (dispatch: any) => {
  dispatch(verifyRequest());
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user !== null) {
      dispatch(receiveLogin(user));
    }
    dispatch(verifySuccess());
  });
};
