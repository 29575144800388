// @flow

import type { typeLabelFlatData } from '../typeLabelFlatData';
import type {
  TimelineExportInputs,
  typeTimelineExportEditList,
  typeTimelineExportSpec
} from '../typeTimeline';

import { convertGoogleDuration } from '../utils/googleTypes';
import type { fragmentClip } from '../fragments/fragmentClip';

export class EditGraph {
  oid: string = 'temp';
  wizardID: string = 'temp';
  wizardPath: string = 'temp';
  buildIndex: number = 0;
  media: { [string]: EditGraphMedia } = {};
  clips: { [string]: EditGraphClip } = {};
  cuts: { [number]: EditGraphCut } = {};
  edges: { [number]: Array<EditGraphCut> } = {};

  static buildExportTimeline(edits: Array<EditGraphCut>): typeTimelineExportSpec {
    const includedMedia = new Map<string, EditGraphMedia>();
    const editList = [];

    edits.forEach((cut, index) => {
      const item = cut.buildExportEditItem('edit_' + index);
      if (item != null) {
        editList.push(item);
        includedMedia.set(cut.mediaID, cut.media);
      }
    });

    const inputs = Array.from(includedMedia.values()).map((media) => media.buildExportInput());

    if (editList == null || editList.length < 1) {
      throw new Error('(buildExportTimeline) No Items to render');
    }

    return {
      inputs,
      editList
    };
  }

  /**
   * Fetches the next nodes in the Edit EditGraph
   * @param {EditGraphCut} cut Current EditGraphCut
   * @returns Array of next cuts
   */

  getNextCuts(cut: EditGraphCut): Array<EditGraphCut> {
    const index = cut.index ?? -1;
    return this.edges?.[index] ?? null;
  }

  /**
   * Insert cut into EditGraph as a child of the parent.
   * @param {EditGraphCut} parentCut
   * @param {EditGraphCut} newCut
   */

  insertNewCut(parentCut: EditGraphCut, newCut: EditGraphCut): EditGraphCut {
    const indexedCut = this.addCut(newCut);
    this.addEdge(parentCut, indexedCut);
    return indexedCut;
  }

  updateCut(cut: EditGraphCut): ?EditGraphCut {
    if (cut?.index) {
      this.cuts[cut.index] = cut;
      return cut;
    }

    return null;
  }

  deleteCut(parentCut: EditGraphCut, newCut: EditGraphCut) {
    this.addCut(newCut);
    this.addEdge(parentCut, newCut);
  }

  addCut(cut: EditGraphCut): EditGraphCut {
    return this.setCut(this.buildIndex, cut);
  }

  setCut(index: number, cut: EditGraphCut): EditGraphCut {
    cut.index = index;
    if (this.cuts?.[index] == null) {
      this.buildIndex++;
    }

    const mediaID = cut.mediaID;
    if (!this.media?.[mediaID]) {
      this.media[mediaID] = cut.media;
    } else {
      cut.media = this.media[mediaID];
    }

    const clipID = cut.clipID;
    if (!this.clips?.[clipID]) {
      this.clips[clipID] = cut.clip;
    } else {
      cut.clip = this.clips[clipID];
    }

    this.cuts[index] = cut;
    return cut;
  }

  removeCut(cut: EditGraphCut) {
    const index = cut.index;
    this.removeEdges(cut);
    // $FlowIssue[incompatible-type]
    this.cuts[index] = null;
  }

  addEdge(source: EditGraphCut, destination: EditGraphCut) {
    if (source?.index == null || destination?.index == null || source === destination) {
      return;
    }

    // const visits = this.depthFirstSearch(destination.index);
    // if (visits.has(source)) {
    //   console.log('addEdge failed, cycilic');
    //   return;
    // }

    const index = source.index;
    this.edges?.[index]?.push(destination) ?? (this.edges[index] = [destination]);
  }

  removeEdge(source: EditGraphCut, destination: EditGraphCut) {
    const index = source?.index ?? -1;
    const newEdges = this.edges[index]?.filter((cut) => cut.index !== destination.index) ?? [];
    if (newEdges.length > 0) {
      this.edges[index] = newEdges ?? [];
    } else {
      delete this.edges[index];
    }
  }

  removeEdges(cut: EditGraphCut) {
    const index = cut?.index ?? -1;
    delete this.edges[index];
  }

  isCyclic(): boolean {
    return false;
  }

  reconstructor(): void {
    Object.keys(this.cuts).forEach((key, idx) => {
      this.cuts[idx].index = idx;
    });

    // Make sure index is correct
    this.buildIndex = Object.keys(this.cuts).length;

    const rawEdges = { ...this.edges };
    this.edges = {};

    Object.keys(this.cuts).forEach((key, index) => {
      const cut = this.cuts[index];
      // Rebuild Edges Filtering out any Cyclical
      if (rawEdges?.[index] && rawEdges[index].length > 0) {
        rawEdges[index].forEach((edge) => this.addEdge(cut, edge));
      }
    });
  }

  // function visit(cut, fn, visited, path) {
  //   const name = cut.name,
  //   vertices = cut.incoming,
  //   names = cut.incomingNames,
  //   len = names.length,
  //   i;
  //   if (!visited) {
  //     visited = {};
  //   }
  //   if (!path) {
  //     path = [];
  //   }
  //   if (visited.hasOwnProperty(name)) {
  //     return;
  //   }
  //   path.push(name);
  //   visited[name] = true;
  //   for (i = 0; i < len; i++) {
  //     visit(vertices[names[i]], fn, visited, path);
  //   }
  //   fn(cut, path);
  //   path.pop();
  // }

  // DAG.prototype.addEdge = function(fromName, toName) {
  //   if (!fromName || !toName || fromName === toName) {
  //   return;
  //   }
  //   const from = this.add(fromName)
  //   const to = this.add(toName);
  //   if (to.incoming.hasOwnProperty(fromName)) {
  //   return;
  //   }
  //   function checkCycle(cut, path) {
  //     if (cut.name === toName) {
  //     throw new Error(“Theres a cycle foo!!!!!“));
  //     }
  //   }
  //   visit(from, checkCycle);
  //   from.hasOutgoing = true;
  //   to.incoming[fromName] = from;
  //   to.incomingNames.push(fromName);
  // };

  depthFirstSearch(startIndex: ?number): Set<EditGraphCut> {
    const visit = (cut: EditGraphCut) => {};
    const startCut = this.cuts[startIndex ?? 0];
    const visited = new Set<EditGraphCut>();

    const dfs = (cut: EditGraphCut) => {
      visit(cut);
      visited.add(cut);
      for (const neighbour of this.getNextCuts(cut) ?? []) {
        if (neighbour && !visited.has(neighbour)) {
          dfs(neighbour);
        }
      }
    };

    dfs(startCut);

    return visited;
  }

  breadthFirstSearch(startIndex: ?number) {
    const visit = (cut: EditGraphCut) => {};

    const startCut = this.cuts[startIndex ?? 0];
    const visited = new Set<EditGraphCut>();
    const queue = [];

    queue.push(startCut);
    visited.add(startCut);

    while (queue.length > 0) {
      const currentCut = queue.shift();
      visit(currentCut);

      for (const neighbour of this.getNextCuts(currentCut) ?? []) {
        if (!visited.has(neighbour)) {
          queue.push(neighbour);
          visited.add(neighbour);
        }
      }
    }
  }
}

export class EditGraphCut {
  index: ?number;
  oid: string;

  offset: number;

  length: number;
  start: number;
  end: number;
  mediaID: string;
  clipID: string;

  media: EditGraphMedia;
  clip: EditGraphClip;

  constructor(data: typeLabelFlatData) {
    if (data && data?.mediaRefId) {
      this.oid = data.mediaRefId + data.frameObjectId;
      this.length = data.frameTimeLeft;
      this.start = data.frameTimestamp;
      this.end = data.segmentEndTime;
      this.mediaID = data.mediaRefId;
      this.clipID = data.mediaRefId + data.labelObjectId;
      this.offset = data?.averageOffset ?? 0;
      this.media = new EditGraphMedia(data);
      this.clip = new EditGraphClip(data);
    }
  }

  // EditGraphCut Interactions
  setCutTimes(start: number, end: number): void {
    const length = end - start;
    if (length <= 0) {
      throw new Error('Cannot have negative duration');
    }
    this.start = start;
    this.end = end;
    this.length = length;
  }

  setStartTime(start: number) {
    this.setCutTimes(start, this.end);
  }

  setEndTime(end: number) {
    this.setCutTimes(this.start, end);
  }

  getMediaOffsetTimes(): { startTimeOffset: number, endTimeOffset: number, warning: boolean } {
    const startTimeOffset = this.start;
    const endTimeOffset = this.end;
    const length = this.end - this.start;
    const warning = length < 5;

    return { startTimeOffset, endTimeOffset, warning };
  }

  getPlayerData(): fragmentClip {
    return {
      id: this.clipID,
      start: this.start ?? 0,
      end: this.end ?? null,
      length: this.length,
      source: 'videographV1',
      title: 'Cut',
      mediaID: this.mediaID,
      clipID: this.clipID,
      mediaPath: this.media?.mediaPath ?? 'unk',
      clipPath: this.clip?.clipPath ?? 'unk',
      thumbnailURL: this.media?.thumbnailURL ?? 'unk',
      spriteSheetURL: this.media?.spriteSheetURL ?? ''
    };
  }

  buildExportEditItem(key: string): ?typeTimelineExportEditList {
    const inputs = [];
    inputs.push(this.media.oid);

    const { startTimeOffset, endTimeOffset, warning } = this.getMediaOffsetTimes();
    if (warning) {
      return null;
    }
    return {
      key,
      inputs,
      startTimeOffset: convertGoogleDuration(startTimeOffset),
      endTimeOffset: convertGoogleDuration(endTimeOffset)
    };
  }
}

export class EditGraphMedia {
  oid: string;
  mediaURL: string;
  mediaPath: string;
  thumbnailURL: string;
  spriteSheetURL: string;
  storageURL: string;

  constructor(data: typeLabelFlatData) {
    if (data && data?.mediaRefId) {
      const mediaID = data?.mediaRefId;
      const workspaceId = data?.workspaceRefId;
      this.oid = mediaID;
      this.mediaPath = `/ws/${workspaceId}/media/${mediaID}`;
      this.mediaURL = '';
      this.thumbnailURL = '';
      this.spriteSheetURL = '';
      this.storageURL = `gs://memory-flow-ai.appspot.com/ws/${workspaceId}/media/${mediaID}_media`;
    }
  }

  getID(): string {
    return this.oid;
  }
  getEditGraphMediaURL(): string {
    return this.mediaURL;
  }
  getThumbnailURL(): string {
    return this.thumbnailURL;
  }

  buildExportInput(): TimelineExportInputs {
    return {
      key: this.oid,
      uri: this.storageURL
    };
  }
}

export class EditGraphClip {
  oid: string;
  clipURL: ?string;
  clipPath: ?string;
  clipStartTime: number;
  clipEndTime: number;
  clipLengthTime: number;

  thumbnailURL: ?string;
  constructor(data?: typeLabelFlatData) {
    if (data && data?.mediaRefId) {
      const mediaID = data.mediaRefId;
      const workspaceId = data.workspaceRefId;
      const clipID = data.mediaRefId + data.labelObjectId;
      this.oid = clipID;
      this.clipPath = `/ws/${workspaceId}/media/${mediaID}/clips/${clipID}`;
      this.clipURL = 'temp';
      this.thumbnailURL = 'temp';
      this.clipStartTime = data.segmentStartTime;
      this.clipEndTime = data.segmentEndTime;
      this.clipLengthTime = data.segmentLengthTime;
    }
  }

  getID(): string {
    return this.oid;
  }
  getClipURL(): ?string {
    return this.clipURL;
  }
  getThumbnailURL(): ?string {
    return this.thumbnailURL;
  }
}
