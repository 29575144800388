// @flow

import type { FirebaseTS } from './firebaseTypes';

export type typeCore = {|
  id?: string,
  createdBy: string,
  createdAt: FirebaseTS,
  updatedAt: FirebaseTS
|};

export const initCore: typeCore = {
  createdBy: 'r8o0mzXiKBb4V4K4jdELZuzoQMw2',
  // $FlowFixMe[incompatible-exact]
  createdAt: new Date(),
  // $FlowFixMe[incompatible-exact]
  updatedAt: new Date()
};
