// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyD1PrYRZSm35Zeg8Ypi_0_NRNu2_DFi6oc',
  authDomain: 'memory-flow-ai.firebaseapp.com',
  projectId: 'memory-flow-ai',
  storageBucket: 'memory-flow-ai.appspot.com',
  messagingSenderId: '773076842283',
  appId: '1:773076842283:web:27b0d1195187fd9d88f76e',
  measurementId: 'G-YEXVLXX582'
};

// Initialize firebase
export const firebase = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebase);
export const provider = new GoogleAuthProvider();
export const auth = getAuth();
export const firestoreDB = getFirestore(firebase);
export const fireFunctions = getFunctions(firebase);

export const fasyncVideoGraphCallable = httpsCallable(fireFunctions, 'fasyncVideoGraphV1');

// export const firebase = firebase.initializeApp(firebaseConfig);
// export const fAnalytics = firebase.analytics();
// export const provider = new firebase.auth.GoogleAuthProvider();
// export const auth = firebase.auth();
// const baseDb = firebase.firestore();

// export constfirestoreDB = baseDb;
// export const firebaseApp = firebase;
