// @flow

import type { typeMedia, typeMediaFragement } from '../../types/typeMedia';
import React, { useState } from 'react';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { addDoc, collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestoreDB } from '../../firebase/firebase.react';

import UploadMedia from './UploadMedia.react';
import { Progress, Header } from 'semantic-ui-react';
import { MediaStatusType, initMedia } from '../../types/typeMedia';
import { ListTaskTypes, initTask } from '../../types/typeTask';
import { useParams } from 'react-router-dom';

export type UploadMetadata = { [string]: string };

type Props = {
  album_id?: string
};

export default function UploadManager(props: Props): React$MixedElement {
  const [uploadState, setUploadState] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [filename, setFilename] = useState('');
  const [totalFiles, setTotalFiles] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState(0);

  const { workspaceId } = useParams();

  const onUpload = async (files: FileList) => {
    setTotalFiles(files.length); // Set the total number of selected files
    setUploadedFiles(0); // Reset the count of uploaded files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      await onUploadFile(file, {});
      setUploadedFiles((uploadedFiles) => uploadedFiles + 1);
    }
  };

  const onUploadFile = async (file: File, metadata: UploadMetadata) => {
    try {
      setUploadState(true);
      setFilename(file.name);

      const storage = getStorage();
      const docPath = '/ws/' + workspaceId + '/media/';

      const targetCollection = collection(firestoreDB, docPath);
      const docMediaRef = doc(targetCollection);
      const docMediaRefID = docMediaRef.id;

      const initDataMedia: typeMedia = {
        ...initMedia,
        name: file.name,
        type: file.type,
        size: file.size,
        lastModified: file.lastModified,
        storageRef: '',
        storageBucket: '',
        storageFolderRef: ''
      };

      if (metadata && Object.keys(metadata).length > 0) {
        initDataMedia.uploadMetadata = metadata;
      }

      const storageRef = ref(storage, docPath + docMediaRefID + '_media');
      const folderRef = ref(storage, docPath + docMediaRefID + '/');
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Handle the progress update
      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadPercentage(progress);
      });

      // Wait for upload to complete
      await uploadTask.then(() => {
        const dataMedia: typeMediaFragement = {
          ...initDataMedia,
          mediaStatus: MediaStatusType.Processing,
          storageBucket: storageRef.bucket,
          storageFolderRef: folderRef.fullPath,
          storageRef: storageRef.fullPath,
          updatedAt: serverTimestamp()
        };

        setDoc(docMediaRef, dataMedia);

        const taskPath = docMediaRef.path + '/tasks';
        const collectionRef = collection(firestoreDB, taskPath);
        const initTasks = [
          ListTaskTypes.TranscodeCreateVP9AndScreenshots_V1,
          ListTaskTypes.TranscodeCreateHEVCAndSpriteSheet_V1,
          ListTaskTypes.TranscodeCreateAudioStream_V1,
          ListTaskTypes.LabelDetectionFromGCI_V1,
          ListTaskTypes.ObjectTrackingFromGCI_V1,
          ListTaskTypes.SpeechTranscriptionFromGCI_V1, // REMOVE
          ListTaskTypes.FaceDetectionFromGCI_V1,
          ListTaskTypes.PersonDetectionFromGCI_V1 // REMOVE
        ];

        initTasks.forEach(async (taskType) => {
          await addDoc(collectionRef, {
            ...initTask,
            taskType
          });
        });
      });

      setUploadState(false);
    } catch (error) {
      console.error('Upload failed:', error);
      setUploadState(false);
    }
  };

  if (uploadState === true) {
    const percentageDone = Math.round(uploadPercentage * 10) / 10;
    return (
      <>
        <div>{`${uploadedFiles} of ${totalFiles}`}</div>
        {/* <div>Uploading: {filename}</div> */}
        <Progress className="noMarg" color="blue" percent={percentageDone} progress />
      </>
    );
  }

  return (
    <UploadMedia
      onUpload={(files) => {
        onUpload(files);
      }}
    />
  );
}
