// @flow

import type { typeMedia } from '../../types/typeMedia';
import type { typeLabel } from '../../types/typeLabel';
import type { fragmentClip } from '../../types/fragments/fragmentClip';

import React from 'react';
import { useEffect, useState } from 'react';
import ClipList from './ClipList.react';
import type { DocumentSnapshot } from '../../types/utils/firebaseTypes';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { firestoreDB } from '../../firebase/firebase.react';

type Props = $ReadOnly<{|
  mediaSnap: DocumentSnapshot<typeMedia>,
  filterList?: Array<?string>
|}>;

export default function ClipListLoader(props: Props): React$MixedElement {
  const [clips, setClips] = useState<fragmentClip[]>([]);

  const mediaData = props.mediaSnap.data();
  const mediaRef = props.mediaSnap.ref;
  useEffect(() => {
    console.log('cll');
    const processLabels = (label: DocumentSnapshot<typeLabel>): fragmentClip => {
      const labelData = label.data();
      return {
        id: label.ref.path,
        title: labelData.labelEntityName,
        source: 'labels',
        thumbnailURL: labelData?.thumbnailURL ?? 'https://react.semantic-ui.com/images/wireframe/image.png',
        length: labelData.segmentLengthTime,
        start: labelData.segmentStartTime,
        end: labelData.segmentEndTime
      };
    };
    const fetchCollections = async () => {
      // console.log('Fetching ', mediaRef.id);
      const mediaColRef = collection(firestoreDB, mediaRef.path + '/labels');
      let clipQuery = query(
        mediaColRef,
        where('segmentLengthTime', '>=', 3),
        orderBy('segmentLengthTime', 'desc'),
        limit(2)
      );
      if (props?.filterList && props.filterList.length > 0) {
        clipQuery = query(
          mediaColRef,
          where('labelEntityName', 'in', props.filterList),
          where('segmentLengthTime', '>=', 3),
          orderBy('segmentLengthTime', 'desc'),
          limit(2)
        );
      }
      const fetchedDocs = await getDocs(clipQuery);

      const listClips = fetchedDocs.docs;
      setClips(listClips.map((clip) => processLabels(clip)));
    };
    fetchCollections();
  }, [mediaRef.id, mediaRef.path, props.filterList]);
  // console.log(mediaRef);
  return <ClipList mediaData={mediaData} clips={clips} />;
}
