// @flow
import type { typeCore } from './utils/typeCore';
import type { typeMediaRef } from './typeMedia';
import type { typeWorkspaceRef } from './typeWorkspace';
import type { DocumentReference, DocumentSnapshot, FirebaseTS } from './utils/firebaseTypes';

import { initCore } from './utils/typeCore';

export const getTomorrowsDate: Date = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);

/* eslint-disable camelcase */
export enum ListTaskTypes {
  TestBaseFunction_V1,
  TranscodeFromPreset_V1,
  TranscodeCreateHEVCAndSpriteSheet_V1,
  TranscodeCreateVP9AndScreenshots_V1,
  TranscodeCreateAudioStream_V1,
  ObjectTrackingFromGCI_V1,
  LabelDetectionFromGCI_V1,
  SpeechTranscriptionFromGCI_V1,
  FaceDetectionFromGCI_V1,
  PersonDetectionFromGCI_V1
}

export enum DataModelType {
  Media = 'media',
  VideoTimeline = 'videoTimelines',
  VideoGraph = 'videographs'
}

export enum TaskStatusType {
  Ready = 0,
  Running = 1,
  Finished = 2,
  Error = 3,
  Suspended = 4
}

export type typeTask = {
  taskExpiration: FirebaseTS | Date,
  taskType: ListTaskTypes,
  taskStatus: TaskStatusType,
  taskPreset: ?string,
  taskModelType?: DataModelType,
  taskRunCount: ?number,
  taskTarget?: ?string,
  taskOutputFolder?: ?string,
  taskDependicies?: [?string],
  fileRunCount: ?number,
  taskReference?: ?string,
  payload?: string,

  workspaceRef?: typeWorkspaceRef,
  mediaRef?: typeMediaRef,
  ...typeCore
};

export type typeTaskFragment = Partial<typeTask>;
export type typeTaskRef = DocumentReference<typeTask>;
export type typeTaskSnap = DocumentSnapshot<typeTask>;

export const initTask: typeTask = {
  taskExpiration: getTomorrowsDate,
  taskPreset: 'default',
  fileRunCount: 0,
  taskRunCount: 0,
  taskType: ListTaskTypes.TestBaseFunction_V1,
  taskStatus: TaskStatusType.Ready,
  ...initCore
};
