// @flow

import { addDoc, doc, collection, setDoc, collectionGroup, query, where, getDocs } from 'firebase/firestore';
import { firestoreDB } from '../../firebase/firebase.react';
import { EditGraph, EditGraphCut } from '../../types/serializr/serialGraphStructure';
import { initTask } from '../../types/typeTask';
import { initTimeline } from '../../types/typeTimeline';
import { initCore } from '../../types/utils/typeCore';
import type { DocumentReference } from '../../types/utils/firebaseTypes';
import type { typeCore } from '../../types/utils/typeCore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import type { typeWizard } from '../../types/typeWizard';
import type { TWizardConfig, TWizardURLObject } from '../../types/utils/wizardTypeUtils';

export const asyncUpdateGraphToFirestore = async (
  DAGraph: any,
  graphPath: string
): Promise<DocumentReference<typeCore>> => {
  const graphDocRef = doc(firestoreDB, graphPath);
  const graphJSONData: any = {
    ...DAGraph,
    updatedAt: new Date()
  };

  const docRef = await setDoc(graphDocRef, graphJSONData, { merge: true });
  return docRef;
};

export const startFormOptions = [
  { key: 0, text: 'Position', value: 'position' },
  { key: 1, text: 'Clip', value: 'clip' },
  { key: 2, text: 'Timecode', value: 'timecode' }
];

export const lookFormOptions = [
  { key: 0, text: 'Exact', value: 'exact' },
  { key: 1, text: 'Momentum', value: 'momentum' },
  { key: 2, text: 'Smooth', value: 'smooth' }
];

export const editFormOptions = [
  { key: 0, text: 'Steady Cuts', value: 'steady' },
  { key: 1, text: 'Fast Cuts', value: 'fast' },
  { key: 2, text: 'Slow Cuts', value: 'slow' }
];

export const durationFormOptions = [
  { key: 0, text: 'Real Short', value: 'reel' },
  { key: 1, text: '2 minutes', value: 'teaser' },
  { key: 2, text: '4 minutes', value: 'longer' }
];

export const searchLabelEntityOptions = [
  { key: 0, text: 'Cars', value: 'car' },
  { key: 1, text: 'Trucks', value: 'truck' },
  { key: 2, text: 'People', value: 'person' },
  { key: 4, text: 'Animal', value: 'animal' },
  { key: 5, text: 'Mountains', value: 'hat' }
];

export const asyncWriteGraphToFirestore = async (
  workspaceId: string,
  DAGraph: any
): Promise<DocumentReference<typeCore>> => {
  const timelineCol = collection(firestoreDB, '/ws/' + workspaceId + '/wizards/');
  const timelineData: any = {
    ...initCore,
    ...DAGraph
  };

  const docRef = await addDoc(timelineCol, timelineData);

  return docRef;
};

export const asyncWritePlaceholderGraphToFirestore = async (
  workspaceId: string,
  configObject: any
): Promise<DocumentReference<typeCore>> => {
  const timelineCol = collection(firestoreDB, '/ws/' + configObject.workspaceId + '/wizards/');
  const timelineData: any = {
    ...initCore,
    isRunning: true,
    initConfig: configObject
  };
  const docRef = await addDoc(timelineCol, timelineData);
  return docRef;
};

export const asyncWriteWizardToFirestore = async (
  workspaceId: string,
  configObject: TWizardConfig,
  configURLs: TWizardURLObject
): Promise<DocumentReference<typeWizard>> => {
  const wizardCollection = collection(firestoreDB, '/ws/' + workspaceId + '/wizards/');
  const wizardDocRef = doc(wizardCollection);

  configObject.workspaceId = workspaceId;
  configObject.wizardID = wizardDocRef.id;
  configObject.wizardPath = wizardDocRef.path;

  console.log('Wizard initURLs:', configURLs);

  const wizardData: typeWizard = {
    ...initCore,
    initConfig: configObject,
    initURLs: configURLs,
    videographRef: null
  };

  console.log('Wizard Data:', wizardData);

  await setDoc(wizardDocRef, wizardData);
  return wizardDocRef;
};

export const asyncWriteTimelineToFirestore = async (
  workspaceId: string,
  graphID: string,
  snapshotState: Array<EditGraphCut>
): Promise<string> => {
  const taskConfig = EditGraph.buildExportTimeline(snapshotState);

  const timelineCol = collection(firestoreDB, '/ws/' + workspaceId + '/videoTimelines/');
  const timelineData = {
    ...initTimeline,
    ...taskConfig,
    title: 'Generated',
    videographRef: doc(firestoreDB, '/ws/' + workspaceId + '/wizards/' + graphID),
    storageRef: '',
    storageBucket: '',
    storageFolderRef: ''
  };

  const docRef = await addDoc(timelineCol, timelineData);
  const taskColRef = collection(firestoreDB, docRef.path + '/tasks');
  await addDoc(taskColRef, {
    ...initTask,
    taskType: 'TranscodeFromPreset_V1',
    taskPreset: 'default'
  });

  return docRef.id;
};

export const asyncFindCSVDataset = async (workspaceId: string): Promise<{ [string]: string }> => {
  const mediaCollection = collection(firestoreDB, '/ws/' + workspaceId + '/media');
  const mediaDocs = await getDocs(mediaCollection);

  const datasetList: { [string]: string } = {};

  await mediaDocs.forEach(async (document) => {
    if (document.data()?.csvURL && document.data()?.csvURL !== '') {
      datasetList[document.id] = document.data().csvURL;
    }
  });

  return datasetList;
};

//   const filesCollection = collectionGroup(firestoreDB, 'files');
//   const q = query(filesCollection, where('type', '==', 'text/csv'));
//   const datasetDocs = await getDocs(q);
//   const datasetList: { [string]: string } = {};

//   const storage = getStorage();
//   await datasetDocs.forEach(async (document) => {
//     if (document.ref.path.includes(workspaceId)) {
//       // regex extract the dataset name from the path
//       const datasetName = document.ref.path.match(/(?<=\/media\/)(\w*)(?=\/)/g);
//       if (datasetName) {
//         const mediaId = String(datasetName[0]);
//         const csvPath = document.data().storageRef;
//         const url = await getDownloadURL(ref(storage, csvPath));
//         datasetList[mediaId] = url;

//         const mediaDocPath = '/ws/' + workspaceId + '/media/' + mediaId;
//         const mediaDoc = doc(firestoreDB, mediaDocPath);

//         if (mediaDoc) {
//           await setDoc(mediaDoc, { csvURL: url }, { merge: true });
//         }
//       }
//     }
//   });

//   return datasetList;
// };
