// @flow

import CollectionCreator from '../../components/collections/CollectionCreator.react';

import React from 'react';
import { Outlet } from 'react-router-dom';

export default function ClipCollector(): React$MixedElement {
  return (
    <>
      <Outlet />
      <CollectionCreator />
    </>
  );
}
