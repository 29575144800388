// @flow

export type gcpTypeDuration = {
  seconds: number,
  nanos?: number
};

export const convertGoogleDuration = (seconds: number): gcpTypeDuration => {
  const timeFormat: gcpTypeDuration = {seconds : 0};

  timeFormat.seconds = Math.trunc(seconds);
  const dec = seconds - timeFormat.seconds;
  const nanos = Math.round(dec * 1000000000);

  if (nanos !== 0) {
    timeFormat.nanos = nanos;
  }

  return timeFormat;
};
