// @flow
import type { TActions, TClipActions } from '../actions';

import { ADD_CLIP, REMOVE_CLIP } from '../actions/';
import { ADD_CLIP_IDX, REMOVE_CLIP_IDX, RESET_CLIP } from '../actions/clip.react';

type clipState = {
  isRunning: boolean,
  clips: Array<?TClipActions>
};

export default (
  state: clipState = {
    isRunning: false,
    clips: []
  },
  action: TActions<TClipActions>
): clipState => {
  const clip = action.payload;

  const addClipAtIdx = (idx: ?number, clip: TClipActions) => {
    const updatedArray = [...state.clips];
    if (idx != null && updatedArray?.[idx]) {
      updatedArray.splice(idx, 0, clip);
    }
    return updatedArray;
  };

  switch (action.type) {
    case RESET_CLIP:
      return {
        ...state,
        clips: []
      };
    case ADD_CLIP:
      return {
        ...state,
        clips: state.clips.concat(clip)
      };
    case REMOVE_CLIP:
      return {
        ...state,
        clips: state.clips.filter((e) => e?.id !== clip?.id)
      };
    case REMOVE_CLIP_IDX:
      return {
        ...state,
        clips: state.clips.filter((e, i) => i !== action?.idx)
      };
    case ADD_CLIP_IDX:
      return {
        ...state,
        clips: addClipAtIdx(action?.idx, clip)
      };
    default:
      return state;
  }
};
