// @flow
import type { dispatchFunc } from '.';

// export const UNDO = 'UNDO_EDIT';
// export const REDO = 'REDO_EDIT';
export const WIZARD_RESET = 'WIZARD_RESET';
export const SELECT_NEXT = 'SELECT_NEXT';
export const SELECT_BACK = 'SELECT_BACK';
export const RENDER_VIDEO = 'RENDER_VIDEO';

const requestResetWizard = (edit: TWizardEdits) => {
  return {
    payload: edit,
    type: WIZARD_RESET
  };
};

const requestSetNext = (edit: TWizardEdits) => {
  return {
    payload: edit,
    type: SELECT_NEXT
  };
};

const requestGoBack = (edit: TWizardEdits) => {
  return {
    payload: edit,
    type: SELECT_BACK
  };
};

// const requestSaveRender = (edit: TWizardEdits) => {
//   return {
//     payload: edit,
//     type: RENDER_VIDEO
//   };
// };

export type TWizardEdits = {
  oid: string,
  index?: number
};

// export function collectionSave() {
//   // fetchTodoByIdThunk is the "thunk function"
//   return async function fetchTodoByIdThunk(dispatch, getState) {
//     const response = await client.get(`/fakeApi/todo/${todoId}`)
//     dispatch(todosLoaded(response.todos))
//   }

// export const collectionCreateNew =
//   (): thunkFunc =>
//   async (dispatch: any, getState: any): Promise<?string> => {
//     const state = getState();
//     const arrayClips = state?.clip?.clips ?? [];

//     let collectionId = null;

//     if (arrayClips.length > 0) {
//       console.log('Thunk: ' + arrayClips.length + ' clips found');
//       const workspaceId = 'OtYwoyQM9uoGaVBnRz69';
//       const docPath = '/ws/' + workspaceId + '/mediaCollections';

//       const newMediaCollection = collection(firestoreDB, docPath);

//       const arrayMedia = new Set();
//       const clipRefs = {};
//       const mediaRefs = {};

//       arrayClips?.forEach((clip, idx) => {
//         const re = RegExp(/workspaces\/\w+\/media\/\w+/);
//         const mediaRef = String(clip.id).match(re)?.[0];

//         mediaRef && arrayMedia.add(mediaRef);
//         clipRefs[idx] = doc(firestoreDB, clip.id);
//       });

//       Array.from(arrayMedia).forEach((ref, idx) => (mediaRefs[idx] = doc(firestoreDB, ref)));

//       const docRef = await addDoc(newMediaCollection, {
//         ...initCore,
//         mediaRefs,
//         clipRefs
//       });

//       console.log(docRef);
//       collectionId = docRef.id;
//     } else {
//       console.log('Thunk: No clips found');
//     }

//     return collectionId;
//   };

export const wizardResetEdit =
  (id: string): dispatchFunc =>
  (dispatch: any) => {
    dispatch(requestResetWizard({ oid: id }));
  };

export const wizardSetNextEdit =
  (id: string, index: number): dispatchFunc =>
  (dispatch: any) => {
    dispatch(requestSetNext({ oid: id, index: index }));
  };

export const wizardGoBackwardsEdit =
  (id: string): dispatchFunc =>
  (dispatch: any) => {
    dispatch(requestGoBack({ oid: id }));
  };
