// @flow

import React from 'react';
import { useEffect, useState } from 'react';
import { firestoreDB } from '../firebase/firebase.react';
import { collection, getDocs, orderBy, query, limit } from 'firebase/firestore';
import TimelineList from '../components/timeline/TimelineList.react';
import { useParams } from 'react-router-dom';
import type { typeTimelineSnap } from "../types/typeTimeline";

export default function TimelinesPage(): React$MixedElement {
  const { workspaceId } = useParams();
  const [timelines, setTimelines] = useState<typeTimelineSnap[]>([]);
  useEffect(() => {
    const fetchCollections = async () => {
      const collectPath = '/ws/' + workspaceId + '/videoTimelines/';
      const q = query(collection(firestoreDB, collectPath), orderBy('createdAt', 'desc'), limit(20));
      const fetchedDocs = await getDocs(q);
      const listCollection = fetchedDocs.docs;
      setTimelines(listCollection);
    };
    fetchCollections();
  }, [workspaceId]);

  return <TimelineList listTimelines={timelines} />;
}
