// @flow
import type { TActions } from '../actions';
import type { TWizardEdits } from '../actions/wizard.react';

import { SELECT_NEXT, SELECT_BACK, WIZARD_RESET } from '../actions';

type State = {
  id: ?string,
  present: number,
  history: Array<number>,
  isComplete: boolean
};

export default (
  state: State = {
    isComplete: false,
    id: null,
    history: [],
    present: 0
  },
  action: TActions<TWizardEdits>
): State => {
  const data = action.payload;
  const history = [...state.history];

  const defaultState: State = {
    isComplete: false,
    id: data?.oid,
    history: [],
    present: 0
  };

  // const addClipAtIdx = (idx: ?number, clip: TClipActions) => {
  //   const updatedArray = [...state.clips];
  //   if (idx != null && updatedArray?.[idx]) {
  //     updatedArray.splice(idx, 0, clip);
  //   }
  //   return updatedArray;
  // };

  switch (action.type) {
    case WIZARD_RESET:
      return {
        ...defaultState
      };
    case SELECT_NEXT:
      return {
        ...state,
        id: data.oid,
        history: history.concat(state.present),
        present: data.index ?? 0
      };
    case SELECT_BACK:
      return {
        ...state,
        present: history.pop(),
        history: history
      };
    // case REMOVE_CLIP_IDX:
    //   return {
    //     ...state,
    //     wizardHistory: state.clips.filter((e, i) => i !== action?.idx)
    //   };
    // case ADD_CLIP_IDX:
    //   return {
    //     ...state,
    //     wizardHistory: addClipAtIdx(action?.idx, clip)
    //   };
    default:
      return state;
  }
};
