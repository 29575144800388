// @flow
import type { TWizardConfig } from '../../types/utils/wizardTypeUtils';

import { addDoc, doc, collection, setDoc } from 'firebase/firestore';
import { firestoreDB } from '../../firebase/firebase.react';

export const asyncUpsertTemplateData = async (workspaceId: string, template: TWizardConfig) => {
  if (template?.templateId != null) {
    const templateId = template?.templateId;
    const docRef = doc(firestoreDB, `/ws/${workspaceId}/templates/${templateId}`);
    await setDoc(docRef, template);
  } else {
    const colRef = collection(firestoreDB, `/ws/${workspaceId}/templates/`);
    await addDoc(colRef, template);
  }
};
