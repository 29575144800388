// @flow

import type { DocumentReference, DocumentSnapshot } from './utils/firebaseTypes';
import type { typeCore } from './utils/typeCore';
import { initCore } from './utils/typeCore';

export type typeWorkspace = {
  title: string,
  description: string,
  countClips: number,
  countMedia: number,
  totalByteSize: number,
  userAccessList: string[],
  ...typeCore
};

export type typeWorkspaceFragment = Partial<typeWorkspace>;
export type typeWorkspaceRef = DocumentReference<typeWorkspace>;
export type typeWorkspaceSnap = DocumentSnapshot<typeWorkspace>;

export const initWorkspace: typeWorkspace = {
  title: 'New Workspace',
  description: 'Workspace Description',
  countClips: 0,
  countMedia: 0,
  totalByteSize: 0,
  userAccessList: [],
  ...initCore
};
