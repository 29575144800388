// @flow

import React from 'react';
import { Container, Segment, Grid, Header, Button } from 'semantic-ui-react';
import WizardSearchBar from '../wizard/WizardSearchBar.react';
import MediaList from './MediaList.react';
import { Link } from 'react-router-dom';

export default function MediaLibrary(): React$MixedElement {
  // const [filterList, setFilterList] = useState<string[]>([]);

  const handleChange = (name: string, value: Array<?string>) => {};
  return (
    <div className="page-body">
      <Container>
        <Grid columns={2}>
          <Grid.Row verticalAlign="middle">
            <Grid.Column>
              <div style={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle' }}>
                <Button icon="left arrow" labelPosition="left" content="Back" as={Link} to="/ws" />
                <Header style={{ marginLeft: '10px', marginTop: 0, marginBottom: 0 }}>Video Library</Header>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="right">
              {/* <Button
                icon="film"
                labelPosition="right"
                content="Create Montage"
                as={Link}
                to="../create"
                positive
              /> */}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Segment placeholder>
          <Grid stackable>
            <Grid.Column width={4}>
              <Header size="small">Instructions</Header>
              <div>To create a new montage, open a video from the list below and select a clip.</div>
              <Header size="small">Search</Header>
              <WizardSearchBar name="search" placeholder="Search Clips" onChange={handleChange} />
              {/* <Header size="small">Album</Header>
              <Menu fluid vertical>
                <Menu.Item
                  name="iceland"
                  active={activeItem === 'iceland'}
                  onClick={() => handleItemClick('iceland')}
                />
                <Menu.Item
                  name="mojave"
                  active={activeItem === 'mojave'}
                  onClick={() => handleItemClick('mojave')}
                  disabled
                />
                <Menu.Item
                  name="Create New Album"
                  icon="plus"
                  active={activeItem === 'create'}
                  onClick={() => handleItemClick('create')}
                  disabled
                />
              </Menu> */}
            </Grid.Column>
            <Grid.Column width={12}>
              <Grid>
                <Grid.Column width={8}>
                  <Header size="small">Videos</Header>
                </Grid.Column>
                <Grid.Column textAlign="right" width={8}></Grid.Column>
              </Grid>
              <MediaList />
            </Grid.Column>
          </Grid>
        </Segment>
      </Container>
    </div>
  );
}
