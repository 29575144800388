// @flow
import React from 'react';
import { Segment, Dimmer, Loader, Image, Header, Container } from 'semantic-ui-react';

export default function AccessDenied(): React$MixedElement {
  return (
    <Container>
      <Header> You do not have access to this content</Header>
      <Segment>
        <Dimmer active inverted>
          <Loader inverted content="Permission Denied" />
        </Dimmer>

        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    </Container>
  );
}
