// @flow

import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
// import EXIF from 'exif-js';
import type { UploadMetadata } from './UploadManager.react';

type Props = { onUpload: (Object: FileList) => void };

export default function UploadMedia(props: Props): ?React$MixedElement {
  const { workspaceId } = useParams();

  const hiddenFileInput = React.useRef<any>(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event: SyntheticInputEvent<HTMLInputElement>) => {
    const files = event.target.files;
    props.onUpload(files); // Ensure upload is completed before moving to the next file
  };

  // const getFileMetadata = (file: File): Promise<UploadMetadata> => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       EXIF.getData(file, () => {
  //         const metadata = EXIF.getAllTags(file);
  //         resolve(metadata);
  //       });
  //     };

  //     reader.onerror = (error) => {
  //       reject(error);
  //     };

  //     reader.readAsDataURL(file);
  //   });
  // };

  if (workspaceId) {
    return (
      <>
        <Button onClick={handleClick} primary>
          Upload Videos
        </Button>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          multiple // Allow multiple file selection
          style={{ display: 'none' }}
          accept="video/*" // Optionally, ensure only video files are selectable
        />
      </>
    );
  }
  return null;
}
