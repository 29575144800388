// @flow
import type { typeCore } from './utils/typeCore';
import type { typeMediaLabels } from './typeMediaLabels';
import type { DocumentReference, DocumentSnapshot } from './utils/firebaseTypes';
import { initCore } from './utils/typeCore';
import type { fragmentContentLinks } from './fragments/fragmentContentLinks';
import type { typeFile } from './typeFile';
import type { fragmentClip } from './fragments/fragmentClip';

export type typeMediaFragement = Partial<typeMedia>;

export enum MediaContentType {
  Audio,
  Image,
  Video
}

export enum MediaStatusType {
  Uploading = 0,
  Processing = 1,
  Done = 2,
  Error = 3
}

export type typeMedia = {
  ...typeCore,
  ...typeFile,
  ...typeMediaLabels,
  ...fragmentContentLinks,
  mediaContentType: MediaContentType,
  mediaDuration: number,
  mediaStatus?: MediaStatusType,
  clipObject?: Partial<fragmentClip>,
  csvURL?: string,
  uploadMetadata?: { [string]: string }
};

export type typeMediaFragment = Partial<typeMedia>;
export type typeMediaRef = DocumentReference<typeMedia>;
export type typeMediaSnap = DocumentSnapshot<typeMedia>;

export const initMedia = {
  ...initCore,
  size: 0,
  lastModified: 0,
  mediaDuration: 0,
  mediaContentType: MediaContentType.Video,
  mediaStatus: MediaStatusType.Uploading
};

// Sanity check
// type checkMedia = initMedia === Partial<typeMedia>;
