// @flow

import type { TWizardConfig } from '../../types/utils/wizardTypeUtils';

export const defaultConfigForWizard: TWizardConfig = {
  workspaceId: 'temp',
  wizardID: 'temp',
  wizardPath: 'temp',

  name: 'test',
  templateName: 'custom',

  graphInputDfMax: 99,
  frameSearchLimit: 500,
  childLimitMin: 3,
  childLimitMax: 5,
  nodeLimitMax: 25,
  nodeLimitMin: 10,

  queryLabelCategoryName: [],
  queryLabelEntityName: [],
  queryLabelConfidence: 0.15,

  durationPreset: 'reel',
  durationTargetTime: 60,
  durationMaxTime: null,
  durationMinTime: null,

  editPreset: 'steady',
  editLengthMin: 2.0,
  editLengthMax: null,
  editSearchTime: 2.0,

  lookPreset: 'exact',
  lookPositionMatch: 0.25,
  lookPositionWeight: 0.25,
  lookContextWeight: 0,
  lookTemporalWeight: 0,

  startPreset: 'position',
  startPosEntityName: null,
  startPosX: 0.5,
  startPosY: 0.5,
  startPosSize: 0.1,

  startFitlerMediaID: null,
  startFitlerStartTime: 0.0,
  startFitlerEndTime: 0.0,

  startTimeEntityName: null,
  startTimeMediaID: null,
  startTimeClipID: null
};
