import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { updatePage } from './actions';
import VizorAppMenuReact from './VizorAppMenu.react';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const pages = ['media', 'timeline', 'collection', 'workspace'];
    const matches = location.pathname.split('/').reverse();
    const match = matches.find((el) => pages.includes(el)) ?? 'unknown';
    dispatch(updatePage(match));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="page-wrapper">
      <VizorAppMenuReact />
      <Container fluid className="page-body">
        <Outlet />
      </Container>
    </div>
  );
}

export default App;
