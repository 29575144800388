// @flow

import { listAddClip } from '../../actions/clip.react';
import type { fragmentClip } from '../../types/fragments/fragmentClip';
import ClipPreview from '../preview/ClipPreview.react';
import React from 'react';
import { useState } from 'react';
import { Button, List, Popup, Header } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

type Props = $ReadOnly<{|
  isActive: boolean,
  clipItem: fragmentClip,
  onClick: (payload: fragmentClip) => void
|}>;

export default function ClipListItem(props: Props): React$MixedElement {
  const { workspaceId, mediaId } = useParams();
  const [isAdded, setIsAdded] = useState(false);
  const dispatch = useDispatch();
  const addToTimelineDisp = (payload: fragmentClip) => dispatch(listAddClip(workspaceId, mediaId, payload));

  const handleAddClipTimeline = (event: SyntheticEvent<>) => {
    setIsAdded(true);
    event.stopPropagation();
    addToTimelineDisp(props.clipItem);
  };

  const handledClickEvent = (event: SyntheticEvent<>) => {
    event.stopPropagation();
    props.onClick(props.clipItem);
  };
  const title = props.clipItem.title?.slice(0, 12);

  return (
    <List.Item onClick={handledClickEvent} active={props.isActive}>
      <List.Content floated="right">
        <Popup trigger={<Button icon="plus" onClick={handleAddClipTimeline} active={isAdded} />}>
          <Header>{title}</Header>
          <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
            <li>length: {Math.round(props.clipItem.length)} sec</li>
            <li>start: {Math.round(props.clipItem.start)} sec</li>
            <li>end: {Math.round(props.clipItem.end)} sec</li>
          </ul>
        </Popup>
      </List.Content>
      <ClipImagePreview clip={props.clipItem} />
    </List.Item>
  );
}

type ClipPreviewProps = {
  clip: fragmentClip
};

function ClipImagePreview(props: ClipPreviewProps): React$MixedElement {
  return <ClipPreview clip={props.clip} />;
}
