// @flow
import WorkspaceCreation from '../components/workspace/WorkspaceCreation.react';

import React from 'react';
import { Segment, Dimmer, Loader, Image, Header, Container } from 'semantic-ui-react';

export default function WorkspaceCreatePage(): React$MixedElement {
  return (
    <Container>
      <Header> Create New Workspace</Header>
      <Segment>
        <WorkspaceCreation />
      </Segment>
    </Container>
  );
}
