// @flow

import { applyMiddleware, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { verifyAuth } from './actions/';

import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

export default function configureStore(persistedState: any): any {
  const composeEnhancers = composeWithDevTools({});
  const store = createStore(rootReducer, persistedState, applyMiddleware(thunk));
  store.dispatch(verifyAuth());
  return store;
}
