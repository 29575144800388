// @flow

import type { typeTimeline } from '../../types/typeTimeline';
import type { DocumentSnapshot } from '../../types/utils/firebaseTypes';

import React from 'react';
import { Container, Segment, Item, Header, Icon } from 'semantic-ui-react';
type Props = {
  listTimelines: Array<DocumentSnapshot<typeTimeline>>
};

export default function TimelineList(props: Props): React$MixedElement {
  return (
    <Container>
      <Segment placeholder>
        <Header>List of Recent Timelines</Header>
        <Item.Group relaxed divided unstackable>
          {props.listTimelines?.map((item, idx) => (
            <TimelineListItem key={idx} item={item} />
          ))}
        </Item.Group>
      </Segment>
    </Container>
  );
}

type ItemProps = {
  item: DocumentSnapshot<typeTimeline>
};

function TimelineListItem(props: ItemProps): React$MixedElement {
  const item = props.item.data();

  const status = item?.mediaURL == null ? ' Rendering ' : ' Finished ';

  return (
    <Item>
      <Item.Image
        size="small"
        src={item.thumbnailURL ?? 'https://react.semantic-ui.com/images/wireframe/image.png'}
      />

      <Item.Content verticalAlign="middle">
        <Item.Header>
          {item.title}: {status}
          {item?.mediaURL == null ? <Icon name="refresh" /> : <Icon name="check" color="green" />}
        </Item.Header>

        <Item.Description>Updated At: {item?.updatedAt.toDate().toLocaleString()}</Item.Description>

        {item?.mediaURL != null && (
          <Item.Extra>
            <a href={item.mediaURL} download>
              Download Link
            </a>
          </Item.Extra>
        )}
      </Item.Content>
    </Item>
  );
}
