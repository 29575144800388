// @flow

import React from 'react';
import { useState } from 'react';
import { Container, Segment, Card, Grid, Icon, Image, Button, Header } from 'semantic-ui-react';
import type { fragmentClip } from '../../types/fragments/fragmentClip';
import ClipPreview from '../preview/ClipPreview.react';

type Props = {
  header: string,
  clipList: Array<?fragmentClip>,
  onClipRemove: (index: number) => void,
  onSubmit: () => void
};

export default function ClipCollection(props: Props): React$MixedElement {
  const [isActive, setIsActive] = useState(true);
  const cards = props.clipList;

  return (
    <div className="fluid-page-body" style={{ marginTop: 0 }}>
      <Container fluid>
        <Header style={{ paddingTop: 0, marginBottom: 0 }}> Timeline</Header>
        <Segment>
          <Grid stretched>
            <Grid.Row>
              <Grid.Column mobile={10} tablet={12} computer={14}>
                <div className="collection-viewport">
                  <Card.Group>
                    {cards?.length > 0 ? (
                      cards.map((card, index) => (
                        <ClipItem key={index} index={index} clip={card} onClipRemove={props.onClipRemove} />
                      ))
                    ) : (
                      <Card>
                        <Card.Content className="card-img-fill">
                          <Card.Description>Add clips from the video player.</Card.Description>
                        </Card.Content>
                      </Card>
                    )}
                  </Card.Group>
                </div>
              </Grid.Column>
              <Grid.Column mobile={6} tablet={4} computer={2}>
                <Button.Group vertical floated="right">
                  <Button
                    positive={cards?.length > 0}
                    icon="film"
                    content="Create"
                    labelPosition="left"
                    onClick={props.onSubmit}
                  />
                  <Button icon="plus" labelPosition="left" content="Save" disabled />
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    </div>
  );
}

type PropsItem = {
  index: number,
  clip: ?fragmentClip,
  onClipRemove: (index: number) => void
};

function ClipItem(props: PropsItem) {
  const onClipRemove = () => props.onClipRemove(props.index);
  return (
    <Card key={props.index} className="collection-card">
      {props.clip?.spriteSheetURL ? (
        <ClipPreview clip={props.clip} />
      ) : (
        <Image src={props.clip?.thumbnailURL} className="card-img-fill" />
      )}
      <Icon link onClick={onClipRemove} name="x" size="big" className="card-icon-x" />
    </Card>
  );
}
