// @flow

import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { loginGoogle } from '../actions';
import { Container, Button, Form, Grid, Header, Image } from 'semantic-ui-react';

type Props = {
  dispatch: any,
  loginError: boolean,
  isAuthenticated: boolean,
  classes: any
};

type State = {
  email: string,
  password: string
};

type ProtectedState = {
  auth: {
    loginError: boolean,
    isAuthenticated: boolean,
    isLoggingIn: boolean
  }
};

class Login extends Component<Props, State> {
  handleSubmitGoogle = () => {
    const { dispatch } = this.props;
    dispatch(loginGoogle());
  };

  loginForm = (): React$MixedElement => (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="black" textAlign="center">
          Welcome to MemoryFlow!
        </Header>
        <Header as="h3" color="teal" textAlign="center">
          <Image src="/logo192.png" /> Log-in to your account
        </Header>
        <Form size="large">
          <Button color="teal" fluid size="small" onClick={this.handleSubmitGoogle}>
            Login with Google
          </Button>
        </Form>
      </Grid.Column>
    </Grid>
  );

  render(): React$MixedElement {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      return <Navigate replace to="/" />;
    } else {
      return (
        <Container component="main" maxWidth="xs">
          {this.loginForm()}
        </Container>
      );
    }
  }
}

function mapStateToProps(state: ProtectedState) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default (connect(mapStateToProps)(Login): any);
