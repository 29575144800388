// @flow
import { Segment, Grid, List, Container, Header, Image, Button, Popup } from 'semantic-ui-react';
import React, { useEffect, useMemo, useState } from 'react';
import { collection, doc, onSnapshot, getDocs, limit, query, orderBy, where } from 'firebase/firestore';
import { firestoreDB } from '../../firebase/firebase.react';
import { useParams } from 'react-router';
import MediaVideoPlayer from './MediaVideoPlayer.react';
import type { typeMedia } from '../../types/typeMedia';
import type { typeLabel } from '../../types/typeLabel';
import { useNavigate } from 'react-router-dom';
import type { DocumentSnapshot, QuerySnapshot } from '../../types/utils/firebaseTypes';
import { listAddClip } from '../../actions/clip.react';
import { useDispatch } from 'react-redux';
import type { fragmentClip } from '../../types/fragments/fragmentClip';
import ClipPreview from '../preview/ClipPreview.react';

export default function MediaItemViewer(): React$MixedElement {
  const { workspaceId, mediaId } = useParams();

  const [mediaData, setMediaData] = useState<?typeMedia>();
  const [labels, setLabels] = useState<?Array<DocumentSnapshot<typeLabel>>>(null);
  const [selectedClip, setSelectedClip] = useState<?fragmentClip>(null);

  const resetLabelClick = () => {
    setSelectedClip(null);
  };

  const onLabelClick = (clip: fragmentClip) => {
    if (selectedClip?.id === clip.id) {
      setSelectedClip(null);
    } else {
      setSelectedClip(clip);
    }
  };

  const clipFromMediaData: any = {
    ...mediaData?.clipObject,
    id: mediaId ?? mediaData?.id,
    title: mediaData?.name,
    thumbnailURL: mediaData?.thumbnailURL,
    type: 'media',
    start: 0,
    length: mediaData?.clipObject?.mediaDuration ?? 100,
    end: mediaData?.clipObject?.mediaDuration ?? 100,
    mediaRefId: mediaId,
    workspaceRefId: workspaceId
  };

  useEffect(() => {
    const docPath = '/ws/' + workspaceId + '/media/' + mediaId;
    const targetDoc = doc(firestoreDB, docPath);
    const unsubscribe = onSnapshot(targetDoc, (doc) => {
      setMediaData(doc.data());
    });
    return () => unsubscribe();
  }, [workspaceId, mediaId]);

  const arrayClips = useMemo(() => {
    if (labels) {
      const processLabels = (label: DocumentSnapshot<typeLabel>): fragmentClip => {
        const mediaClip = mediaData?.clipObject;
        const labelData = label.data();
        return {
          ...mediaClip,
          ...mediaClip?.clipObject,
          id: label.ref.path,
          title: labelData.labelEntityName,
          source: 'labels',
          thumbnailURL: mediaData?.thumbnailURL,
          length: labelData.segmentLengthTime,
          start: labelData.segmentStartTime,
          end: labelData.segmentEndTime,
          mediaRefId: mediaId,
          workspaceRefId: workspaceId
        };
      };
      return labels.map((label) => processLabels(label));
    }
    const emptyClip: fragmentClip[] = [];
    return emptyClip;
  }, [labels, mediaData]);

  useEffect(() => {
    const docPath = '/ws/' + workspaceId + '/media/' + mediaId + '/labels';
    const targetDoc = collection(firestoreDB, docPath);

    const q = query(
      targetDoc,
      where('segmentLengthTime', '>', 2),
      orderBy('segmentLengthTime', 'desc'),
      limit(10)
    );

    const querySnapshot = getDocs(q);
    querySnapshot.then((labelSnaps: QuerySnapshot<typeLabel>) => {
      setLabels(labelSnaps.docs);
    });
  }, [workspaceId, mediaId]);

  const navigate = useNavigate();

  return (
    <div className="fluid-page-body">
      <Container fluid>
        <Segment>
          <div style={xStyles.padded}>
            <Button
              color="grey"
              content="Go Back"
              icon="left arrow"
              labelPosition="left"
              onClick={() => navigate(-1)}
            />
          </div>
          <Grid columns={2} stackable stretched>
            <Grid.Column tablet={10} computer={13}>
              <MediaVideoPlayer
                mediaURL={mediaData?.mediaURL ?? null}
                mediaURLFallback={mediaData?.mediaURLFallback ?? null}
                thumbnailURL={mediaData?.thumbnailURL ?? null}
                selectedClip={selectedClip}
              />
            </Grid.Column>
            <Grid.Column tablet={6} computer={3}>
              <div style={xStyles.sideRail}>
                <Header>Generated Clips</Header>
                <List divided selection verticalAlign="middle">
                  {arrayClips?.map((clip, idx) => (
                    <ClipListItem
                      key={idx}
                      isActive={selectedClip?.id === clip.id}
                      clipItem={clip}
                      onClick={onLabelClick}
                    />
                  ))}
                </List>
              </div>
            </Grid.Column>
          </Grid>
        </Segment>
      </Container>
      <br />
      {/* <Container>
        <Header>Label Metadata</Header>
        <Segment clearing>
          <div>{mediaData?.listTrackedLabels?.join(', ')}</div>
          <div>{mediaData?.listCategoryLabels?.join(', ')}</div>
          <div>{mediaData?.listLabels?.join(', ')}</div>
        </Segment>
      </Container> */}
    </div>
  );
}

type LabelProps = $ReadOnly<{|
  isActive: boolean,
  clipItem: fragmentClip,
  onClick: (payload: fragmentClip) => void
|}>;

function ClipListItem(props: LabelProps): React$MixedElement {
  const { workspaceId, mediaId } = useParams();
  const [isAdded, setIsAdded] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addToTimelineDisp = (payload: fragmentClip) => dispatch(listAddClip(workspaceId, mediaId, payload));

  const handleAddClipTimeline = (event: SyntheticEvent<>) => {
    setIsAdded(true);
    event.stopPropagation();
    addToTimelineDisp(props.clipItem);
  };

  const handledClickEvent = (event: SyntheticEvent<>) => {
    event.stopPropagation();
    props.onClick(props.clipItem);
  };
  const title = props.clipItem.title?.slice(0, 12);

  return (
    <List.Item onClick={handledClickEvent} active={props.isActive}>
      <List.Content>
        <div className="clip-item-container">
          <div className="aspect-ratio-content">
            <Image>{props.clipItem?.spriteSheetURL ? <ClipPreview clip={props.clipItem} /> : null}</Image>
          </div>
          <div className="floating-content">
            <Button.Group>
              <Popup
                trigger={<Button positive size="tiny" icon="plus" onClick={handleAddClipTimeline} />}
                content={
                  <>
                    <Header>Instructions</Header>
                    <div>Add the clip to the collection</div>
                  </>
                }
              />
              <Popup
                trigger={<Button size="tiny" icon="info" />}
                content={
                  <>
                    <Header>{props.clipItem.title?.slice(0, 12)}</Header>
                    <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                      <li>length: {Math.round(props.clipItem.length)}</li>
                      <li>start: {Math.round(props.clipItem.start)}</li>
                      <li>end: {Math.round(props.clipItem.end)}</li>
                    </ul>
                  </>
                }
              />
            </Button.Group>
          </div>
        </div>
      </List.Content>
    </List.Item>
  );
}

const xStyles = {
  padded: {
    paddingBottom: '15px'
  },
  videPlayer: {
    maxHeight: '70vh'
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center'
  },
  flexRow: {
    flexDirection: 'column',
    paddingBottom: '10px',
    paddingRight: '20px'
  },
  sideRail: {
    height: '0',
    minHeight: '200px',
    overflowX: 'auto'
  }
};
