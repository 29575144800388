// @flow

import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { connect } from 'react-redux';
import HomePage from './pages/HomePage.react';
import Login from './pages/Login.react';
import TimelineListPage from './pages/TimelineListPage.react';
import MediaLibraryPage from './pages/MediaLibraryPage.react';
import MediaViewerPage from './pages/MediaViewerPage.react';
import TimelineViewerPage from './pages/TimelineViewerPage.react';
import WorkspaceListPage from './pages/WorkspaceListPage.react';
import App from './VizorApp.react';
import ProtectedRoute from './pages/ProtectedRoute.react';
import ClipCollector from './pages/overlays/ClipCollector.react';
import TemplatePage from './pages/TemplatePage.react';
import WizardEditorPage from './pages/WizardEditorPage.react';
import WizardStartPage from './pages/WizardStartPage.react';
import ConfigBuildPage from './pages/ConfigBuildPage.react';
import ConfigEditPage from './pages/ConfigEditPage.react';
import TemplateEditPage from './pages/TemplateEditPage.react';
import ClipQueryPage from './pages/ClipQueryPage.react';
import WorkspaceCreatePage from './pages/WorkspaceCreatePage.react';

type ProtectedProps = {
  currentPage: string,
  isAuthenticated: boolean,
  isVerifying: boolean
};

type ProtectedState = {
  auth: {
    isAuthenticated: boolean,
    isVerifying: boolean
  },
  router: {
    currentPage: string
  }
};
function Router(props: ProtectedProps) {
  const { isAuthenticated, isVerifying } = props;

  const allRoutes = [
    {
      path: '/',
      element: <App />,
      children: [
        {
          index: true,
          element: <HomePage />
        },
        {
          path: '/start',
          element: <ProtectedRoute isAuthenticated={isAuthenticated} isVerifying={isVerifying} />,
          children: [
            {
              index: true,
              element: <WorkspaceCreatePage />
            }
          ]
        },
        {
          path: '/ws',
          element: <ProtectedRoute isAuthenticated={isAuthenticated} isVerifying={isVerifying} />,
          children: [
            {
              index: true,
              element: <WorkspaceListPage />
            }
          ]
        },
        {
          path: '/ws/:workspaceId',
          element: <ClipCollector />,
          children: [
            {
              path: 'media',
              children: [
                {
                  index: true,
                  element: <MediaLibraryPage />
                },
                {
                  path: ':mediaId',
                  children: [
                    {
                      path: 'view',
                      element: <MediaViewerPage />
                    },
                    {
                      path: 'edit',
                      element: <ProtectedRoute isAuthenticated={isAuthenticated} isVerifying={isVerifying} />,
                      children: [
                        {
                          index: true,
                          element: <MediaViewerPage />
                        }
                      ]
                    },
                    {
                      path: 'create',
                      element: <ProtectedRoute isAuthenticated={isAuthenticated} isVerifying={isVerifying} />,
                      children: [
                        {
                          index: true,
                          element: <WizardStartPage />
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              path: 'clips',
              element: <ClipCollector />,
              children: [
                {
                  index: true,
                  element: <ClipQueryPage />
                }
              ]
            },
            {
              element: <ProtectedRoute isAuthenticated={isAuthenticated} isVerifying={isVerifying} />,
              children: [
                {
                  path: 'create',
                  children: [
                    {
                      index: true,
                      element: <WizardStartPage />
                    },
                    {
                      path: 'config',
                      element: <ConfigBuildPage />
                    },
                    {
                      path: 'config/:configId',
                      element: <ConfigEditPage />
                    },
                    {
                      path: 'editor/:graphId',
                      element: <WizardEditorPage />
                    },
                    {
                      path: 'template',
                      element: <TemplatePage />
                    },
                    {
                      path: 'template/:templateId',
                      element: <TemplateEditPage />
                    }
                  ]
                },
                {
                  path: 'edit',
                  children: [
                    {
                      index: true,
                      element: <TimelineListPage />
                    },
                    {
                      path: 'editor/:graphId',
                      element: <WizardEditorPage />
                    },
                    {
                      path: 'timeline',
                      element: <TimelineListPage />
                    },
                    {
                      path: 'timeline/:timelineId',
                      children: [
                        {
                          index: 'edit',
                          element: <TimelineListPage />
                        },
                        {
                          path: 'view',
                          element: <TimelineViewerPage />
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          element: <Login />,
          path: '/login'
        }
      ]
    }
  ];

  const router = createBrowserRouter(allRoutes);
  return <RouterProvider router={router} />;
}

function mapStateToProps(state: ProtectedState) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    currentPage: state.router.currentPage
  };
}

export default (connect(mapStateToProps)(Router): any);
