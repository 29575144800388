// @flow

import type { typeWizardSnap } from '../../types/typeWizard';

import React from 'react';

import { List, Header, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

type Props = {
  listWizards: ?Array<typeWizardSnap>
};

export default function WizardDetails(props: Props): React$MixedElement {
  return (
    <div>
      <Header>Recently Made</Header>
      <List divided>
        {props.listWizards?.map((item, idx) => (
          <WizardListItem key={idx} item={item} />
        ))}
      </List>
    </div>
  );
}

type ItemProps = {
  item: typeWizardSnap
};

function WizardListItem(props: ItemProps): React$MixedElement {
  const snap = props.item;
  const data = snap.data();

  const creationDate = data?.createdAt?.toDate() ?? null;
  return (
    <List.Item>
      <List.Content floated="right">
        <Button.Group>
          {/* <Button>Edit</Button> */}
          <Button as={Link} to={`editor/${snap.id}`} primary>
            Open Editor
          </Button>
        </Button.Group>
      </List.Content>
      <List.Content>
        <List.Header>Reference: {String(snap.id).slice(0, 6).toUpperCase()}</List.Header>
        <List.Description>{creationDate && creationDate.toLocaleString()}</List.Description>
      </List.Content>
    </List.Item>
  );
}
