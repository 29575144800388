// @flow
import { Dimmer, Loader } from 'semantic-ui-react';
import React, { useEffect, useMemo, useRef } from 'react';
import { Player, BigPlayButton, ControlBar, PlayToggle } from 'video-react';
import type { fragmentClip } from '../../types/fragments/fragmentClip';

type Props = $ReadOnly<{|
  thumbnailURL: ?string,
  mediaURL: ?string,
  mediaURLFallback: ?string,
  selectedClip?: ?fragmentClip
|}>;

export default function MediaVideoPlayer(props: Props): React$MixedElement {
  if (props.mediaURL == null) {
    return (
      <Dimmer>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <VideoPlayer
      thumbnailURL={props.thumbnailURL}
      mediaURL={props.mediaURL}
      selectedClip={props.selectedClip}
    />
  );
}

type PlayerProps = $ReadOnly<{|
  thumbnailURL: ?string,
  mediaURL: string,
  selectedClip?: ?fragmentClip
|}>;

function VideoPlayer(props: PlayerProps): React$MixedElement {
  const refPlayer = useRef(null);
  const clipData = props?.selectedClip ?? null;

  const startTime = clipData?.start ?? 0;

  useEffect(() => {
    let unsubscribe = () => {};

    function videoPlayerCallback(state: any) {
      if (clipData?.end && state.currentTime > clipData?.end) {
        refPlayer?.current?.seek(clipData?.start);
      }
    }

    if (clipData) {
      unsubscribe = refPlayer?.current?.subscribeToStateChange(videoPlayerCallback) ?? unsubscribe;
    }

    return () => unsubscribe && unsubscribe();
  }, [clipData]);

  useMemo(() => {
    refPlayer?.current?.seek(startTime);
  }, [startTime]);

  return (
    <div className="limit-screen">
      <Player
        ref={refPlayer}
        startTime={startTime}
        src={props.mediaURL}
        aspectRatio="16:9"
        fluid={true}
        className="limit-screen"
      >
        <BigPlayButton position="center" />
        <ControlBar autoHide={false}>
          <PlayToggle />
        </ControlBar>
      </Player>
    </div>
  );
}
