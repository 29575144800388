// @flow

import React, { useEffect, useState } from 'react';
import { firestoreDB } from '../firebase/firebase.react';
import { collection, getDocs, limit, query, where } from 'firebase/firestore';

import { Segment, Grid, Header, Container, Form, Card } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import WizardSearchBar from '../components/wizard/WizardSearchBar.react';
import ClipListLoader from '../components/clips/ClipListLoader.react';
import type { typeMediaSnap } from "../types/typeMedia";

export default function ClipQueryPage(): React$MixedElement {
  const { workspaceId } = useParams();
  const [filterList, setFilterList] = useState<Array<?string>>([]);
  const [mediaList, setMediaList] = useState<Array<typeMediaSnap>>([]);
  useEffect(() => {
    console.log('cqp');
    const fetchCollections = async () => {
      const collectPath = '/ws/' + workspaceId + '/media/';
      const mediaColRef = collection(firestoreDB, collectPath);
      let queryDocs = query(mediaColRef, limit(12));
      if (filterList.length > 0) {
        queryDocs = query(
          mediaColRef,
          where('listTrackedLabels', 'array-contains-any', filterList),
          limit(12)
        );
      }
      const fetchedDocs = await getDocs(queryDocs);
      const listCollection = fetchedDocs.docs;
      setMediaList(listCollection);
    };
    fetchCollections();
  }, [workspaceId, filterList]);

  const handleSubmit = (name: string, value: string) => {
    console.log(value);
  };

  const handleChange = (name: string, value: Array<?string>) => {
    setFilterList(value);
  };
  return (
    <Container>
      <Header>Matching Clips</Header>
      <Segment>
        <Form onSubmit={handleSubmit}>
          <Grid padded centered doubling columns={1}>
            <Grid.Column moblie={16} tablet={12} computer={8}>
              <WizardSearchBar name="search" placeholder="Search Clips" onChange={handleChange} />
            </Grid.Column>
          </Grid>
          <Grid padded doubling columns={4}>
            {mediaList.map((item, idx) => (
              <Grid.Column key={idx}>
                <Card>
                  <ClipListLoader mediaSnap={item} filterList={filterList} />
                </Card>
              </Grid.Column>
            ))}
          </Grid>
        </Form>
      </Segment>
    </Container>
  );
}
