// @flow

import React from 'react';
import { Container } from 'semantic-ui-react';
import WizardEditor from '../components/editor/WizardEditor.react';

export default function CreationBuilderPage(): React$MixedElement {
  return (
    <Container fluid className="page-body">
      <WizardEditor />
    </Container>
  );
}
