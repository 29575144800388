// @flow

import React from 'react';
import { useEffect, useState } from 'react';
import { getDocs, collection } from 'firebase/firestore';

import { Link, useParams } from 'react-router-dom';
import { List, Button, Grid, Header } from 'semantic-ui-react';
import { firestoreDB } from '../../firebase/firebase.react';
import type { DocumentSnapshot } from '../../types/utils/firebaseTypes';
import type { typeTemplate, typeTemplateSnap } from "../../types/typeTemplate";

export default function TemplateList(): React$MixedElement {
  const [templateDocs, setTemplateDocs] = useState<typeTemplateSnap[]>([]);

  const { workspaceId } = useParams();
  useEffect(() => {
    const fetchTemplates = async () => {
      const docPath = '/ws/' + workspaceId + '/templates';
      const fetchedDoc = await getDocs(collection(firestoreDB, docPath));
      setTemplateDocs(fetchedDoc.docs);
    };

    fetchTemplates();
  }, [workspaceId]);

  return (
    <Grid divided stackable>
      <Grid.Column>
        <Header>Available Templates</Header>
        <List divided>
          {templateDocs && templateDocs?.map((item, idx) => <TemplateListItem key={idx} item={item} />)}
        </List>
      </Grid.Column>
    </Grid>
  );
}

type ItemProps = {
  item: DocumentSnapshot<typeTemplate>
};

function TemplateListItem(props: ItemProps): React$MixedElement {
  const snap = props.item;
  const data = snap.data();

  return (
    <List.Item>
      <List.Content floated="right">
        <Button.Group>
          <Button as={Link} to={`template/${snap.id}`} disabled color="grey">
            Edit
          </Button>
        </Button.Group>
      </List.Content>
      <List.Icon flipped="horizontally" name="magic" size="large" verticalAlign="middle" />

      <List.Content>
        <List.Header>{data?.templateName ?? String(snap.id).slice(0, 6).toUpperCase()}</List.Header>
        <List.Description>
          Look: {data?.lookPreset ?? 'exact'}, Edit: {data?.editPreset ?? 'steady'}, Duration:{' '}
          {data?.durationTargetTime ?? '60'} Secs
        </List.Description>
      </List.Content>
    </List.Item>
  );
}
