// @flow

// import type { typeRef } from './typeRef'

export type typeUser = {
  uid: string,
  createdBy: string,
  displayName: string,
  email: string,
  emailVerified: boolean,
  photoURL: string,
  providerId: string,
  lastSignInTime: string,
  creationTime: string,
}


export const initUser: typeUser = {
  uid: '',
  createdBy: '',
  displayName: '',
  email: '',
  emailVerified: false,
  photoURL: '',
  providerId: '',
  lastSignInTime: '',
  creationTime: '',
}

