// @flow

import React, { useEffect, useState } from 'react';
import { firestoreDB } from '../firebase/firebase.react';
import { collection, getDocs } from 'firebase/firestore';
import TemplateEditor from '../components/template/TemplateEditor.react';

import { List, Segment, Grid, Header, Container } from 'semantic-ui-react';
import { defaultConfigForWizard } from '../types/utils/wizardUtils';
import { useParams } from 'react-router-dom';
export default function TemplatePage(): React$MixedElement {
  const { workspaceId } = useParams();
  const [timelines, setTimelines] = useState<string[]>([]);
  useEffect(() => {
    const fetchCollections = async () => {
      const collectPath = '/ws/' + workspaceId + '/mediaCollections/';
      const fetchedDocs = await getDocs(collection(firestoreDB, collectPath));
      const listCollection = fetchedDocs.docs;
      setTimelines(listCollection);
    };
    fetchCollections();
  }, [workspaceId]);

  return (
    <Container>
      <Header>Templates</Header>
      <Segment>
        <Grid divided stackable columns={2}>
          <Grid.Column>
            <List divided>{timelines.map((item, idx) => idx)}</List>
          </Grid.Column>
        </Grid>
      </Segment>
      <Header>Create Custom Template</Header>
      <TemplateEditor defaultTemplate={defaultConfigForWizard} />
    </Container>
  );
}
